import {updateArr} from 'utils/helperFunction'
import * as actions from './action'

const initialStore = {
    data : [],
    currentPage : 1,
    total : undefined,
    searchParams : {}
}

export default function offerReducer(state = initialStore, action){
    switch(action.type){
        case actions.SET_OFFER_META:
            // console.log(action.total)
            return {
                ...state,
                total : action.total || state.data?.length,
                currentPage : action.currentPage,
                searchParams : action.searchParams
            }
            case actions.SET_OFFER_DATA:
                return {
                    ...state,
                    data : action.payload
                }
            case actions.DELETE_OFFER:
                // console.log('payload', action.payload)
                const withoutDeleted = state.data.filter(offer => offer.id !== action.payload)
                return {
                    ...state,
                    data : withoutDeleted
                }
            case actions.CREATE_AN_OFFER:
                // console.log('on create offer',action.payload)
                const newData = [action.payload, ...state.data]
                return {
                    ...state, 
                    data : newData
                }
            case actions.UPDATE_OFFER:
                // console.log('on update offer', action.payload)
                const updated_offer = updateArr(state.data, action.payload)
                return {
                    ...state,
                    data : updated_offer
                }
            default :
                return state
    }
}