import { updateArr } from 'utils/helperFunction'
import * as actions from './action'

const initalStore = {
  data: [],
  total: undefined,
  keyword: null,
  business: null,
  currentPage: 1,
  from_date: null,
  to_date: null,
  searchParams: {},
  shouldAddQuestionOnSubmit: true,
  allSurveyCount: 0
}

export default function surveyReducer(state = initalStore, action) {
  switch (action.type) {
    case actions.SET_SURVEY_META:
      console.log('survey mete', action)
      return {
        ...state,
        total: action.total,
        currentPage: action.currentPage,
        searchParams: action.searchParams,
        allSurveyCount: action?.isAllSurvey ? action?.total : state?.allSurveyCount
      }
    case 'SET_HAS_META':
      return {
        ...state,
        hasMeta: action.payload,
      }
    case 'SET_SURVEY_QUESTIONS':
      return {
        ...state,
        questions: action.payload,
      }
    case actions.SET_SURVEY_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case actions.CREATE_A_SURVEY:
      const newData = [action.payload, ...state.data]
      return {
        data: newData,
      }
    case actions.DELETE_SURVEY:
      const withoutDeleted = state.data.filter(survey => survey.id !== action.payload)
      return {
        ...state,
        data: withoutDeleted,
      }
    case actions.UPDATE_SURVEY:
      // console.log(action.payload)
      const updated_survey = updateArr(state.data, action.payload)
      console.log(updated_survey)
      return {
        ...state,
        data: updated_survey,
      }
    case actions.SET_SURVEY_KEYWORD:
      // console.log('survey part in reducer')
      return {
        ...state,
        keyword: action.payload,
        currentPage: 1,
      }
    case actions.SET_SURVEY_BUSINESS:
      // console.log('business part in reducer')
      return {
        ...state,
        business: action.payload,
        currentPage: 1,
      }
    case actions.CLEAR_SURVEY_SESSION:
      // console.log('session was cleared');
      return {
        ...state,
        business: undefined,
        keyword: undefined,
        currentPage: 1,
      }
    case actions.SET_SURVEY_DATE:
      return {
        ...state,
        from_date: action.from_date,
        to_date: action.to_date,
      }
    case actions.SHOULD_ADD_QUESTION:
      return {
        ...state,
        shouldAddQuestionOnSubmit: action.payload,
      }
    case actions.UPDATE_STATUS:
      const newState = state.data.map(obj =>
        obj.id === action.payload.id
          ? { ...obj, active: action.payload.active, status: action.payload.status }
          : obj,
      )

      return {
        ...state,
        data: newState,
      }
    default:
      return state
  }
}
