import React, { useState, useEffect } from 'react'
import { withRouter, Link, matchPath } from 'react-router-dom'
import { Button } from 'antd'

const BreadCrumb = ({ history, location }) => {
  let [crumb, setCrumb] = useState([])
  const { pathname } = location
  const page = pathname.split('/')[1]
  const id = pathname.split('/')[3]
  const formAction = pathname.split('/')[2]

  const navigateToEdit = () => {
    history.push(`/${page}/edit/${id}`)
  }

  useEffect(() => {
    let arr = []
    let pathName = pathname.split('/')[1]
    arr.push({ label: pathName, link: `/${pathName}` })
    if (formAction === 'edit') {
      arr.push({ label: `Edit ${pathName}`, link: '#' })
    } else if (formAction === 'add') {
      arr.push({ label: `Create ${pathName}`, link: '#' })
    } else if (formAction === 'detail') {
      arr.push({ label: `${pathName} Details`, link: '#' })
    } else if (formAction === 'responses') {
      arr.push({ label: `${pathName} Responses`, link: '#' })
    } else if (pathName === 'admin' && pathname.split('/')[2]) {
      arr.push({ label: `${pathname.split('/')[2]} `, link: `/admin/${pathname.split('/')[2]}` })
    }
    setCrumb(arr)
  }, [formAction, history, pathname])

  const renderBreadCrumb = crumb.map(bread => {
    return (
      <li key={bread.link} className="text-capitalize mx-0 breadcrumb-item">
        <Link to={bread.link}>{bread.label.split('-').join(' ')}</Link>
      </li>
    )
  })
  const renderTitle = crumb.map(bread => bread.label.split('-').join(' '))[1]
  const isFormPath = matchPath(pathname, {
    path: [
      formAction === 'add' ||
        formAction === 'edit' ||
        formAction === 'detail' ||
        formAction === 'responses',
    ],
  })
  const isAdminPath = pathname.split('/')[1] === 'admin' && pathname.split('/')[2]

  return (
    <>
      {(isFormPath || isAdminPath) && (
        <div className="bg-white mt-3" style={{ height: 84 }}>
          <div className="d-flex justify-content-between">
            <ol style={{ listStyle: 'none' }} className="d-flex px-3 breadcrumb">
              {renderBreadCrumb}
            </ol>
            {formAction === 'detail' && (
              <Button
                className="mt-2"
                style={{ marginRight: 100 }}
                onClick={navigateToEdit}
                type="primary"
                icon="edit"
              >
                Edit
              </Button>
            )}
          </div>
          <h5 className="text-capitalize ml-3 mt-n4 font-weight-bold">{renderTitle}</h5>
        </div>
      )}
    </>
  )
}

export default withRouter(BreadCrumb)
