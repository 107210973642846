import Axios from 'axios'
import { baseUri, urlWithParams, adUrl, statusPatchUrl } from 'helpers/urls'
import { getApiCall, genericApiCall } from 'helpers/apiCall'
import {
  setADData,
  setADMeta,
  updateAD,
  deleteADAction,
  createAnAd,
  updateAdsStatus,
} from 'redux/ads/action'
import { message } from 'antd'
import { getsearchParamsFromRequestBody } from 'utils/helperFunction'
import { setErrors } from 'redux/errors/action'

export function getAllAd({ params, finalCallback }) {
  return dispatch => {
    dispatch(setADData([]))
    const url = urlWithParams(`${baseUri}/${adUrl}`, params)
    getApiCall({ url, dispatch })
      .then(resp => {
        if (resp && resp.success) {
          const searchParams = getsearchParamsFromRequestBody({
            page: resp?.data?.current_page.toString(),
            params,
          })
          dispatch(setADData(resp?.data?.data))
          dispatch(
            setADMeta({
              total: resp?.data?.total,
              currentPage: resp?.data?.current_page,
              isAllAd: params?.status === undefined ? true : false,
              searchParams,
            }),
          )
        } else {
          message.error(`couldn't fetch all ads. Something went wrong`)
        }
      })
      .catch(err => console.log(err))
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function getAd({ id, callback }) {
  const url = `${baseUri}/${adUrl}/${id}`
  return dispatch => {
    getApiCall({ url, dispatch })
      .then(resp => {
        if (resp?.success) {
          callback(resp?.data?.data)
        } else {
          message.error(`Couldn't fetch an ads. Something went wrong`)
        }
      })
      .catch(err => console.warn(err))
  }
}

export function createAd({ body, successCallback, finalCallback }) {
  const url = `${baseUri}/${adUrl}`
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('povToken')}`,
    },
  }
  return dispatch => {
    Axios.post(url, body, config)
      .then(resp => {
        if (resp?.data?.success) {
          dispatch(createAnAd(resp?.data?.data?.data))
          successCallback(resp?.data?.data?.data?.id, resp?.data?.data?.data?.status)
          message.success(resp?.data?.message)
        } else {
          message.error('Something went wrong')
        }
      })
      .catch(err => {
        if (err.response) {
          const {
            response: { data },
          } = err
          dispatch(setErrors(data?.data?.errors))
        }
      })
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function editAd({ body, successCallback, id, finalCallback }) {
  return dispatch => {
    const url = `${baseUri}/${adUrl}/${id}`
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('povToken')}`,
      },
    }
    Axios.post(url, body, config)
      .then(resp => {
        if (resp?.data?.success) {
          dispatch(updateAD(resp?.data?.data?.data))
          successCallback(resp?.data?.data?.data?.id, resp.data?.data?.data?.status)
          message.success(resp?.data?.message)
        } else {
          message.error('Something went wrong')
        }
      })
      .catch(err => {
        if (err.response) {
          const {
            response: { data },
          } = err
          dispatch(setErrors(data?.data?.errors))
        }
      })
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function deleteAd({ id }) {
  return dispatch => {
    const url = `${baseUri}/${adUrl}/${id}`
    genericApiCall({ url, method: 'DELETE' })
      .then(resp => {
        if (resp?.data?.success) {
          dispatch(deleteADAction(id))
          message.success(resp?.data?.message)
        } else {
          message.error(resp?.data?.message)
        }
      })
      .catch(err => console.warn(err))
  }
}

export function changeAdStatus({ id, body, finalCallback }) {
  return dispatch => {
    const url = `${baseUri}/${adUrl}/${statusPatchUrl}/${id}`
    genericApiCall({ body, url, method: 'PATCH' })
      .then(resp => {
        if (resp?.data?.success) {
          dispatch(updateAdsStatus(resp?.data?.data?.data))
          message.success('Status Changed Successfully.')
          return
        }
        message.error('Something went wrong !')
      })
      .catch(err => console.warn(err))
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}
