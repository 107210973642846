/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Tabs } from 'antd'
import Edit from 'components/Question/Edit'
import Create from 'components/Question/Create'
import { setErrors } from 'redux/errors/action'
import { createFeedback, editFeedback, getFeedback } from 'services/feedback'
import Fields from './Fields'

const FeedbackForm = ({ dispatch, match, history, location }) => {
  const [fields, setFields] = useState({})
  const [submtting, setSubmitting] = useState(false)
  const [action, setAction] = useState(match.params.action)
  const [defaultTabKey, setDefaultTabKey] = useState('fields')
  const [feedbackId, setFeedbackId] = useState(match.params.id)

  const appliedBusiness = useSelector(state => state.user.business)

  useEffect(() => {
    if (action !== 'add' && action !== 'edit') {
      history.push('/feedback')
    }
  }, [action])

  useEffect(() => {
    if (feedbackId) {
      dispatch(getFeedback({ id: feedbackId, callback: resp_data => setFields(resp_data) }))
    }

    if (action === 'edit' && location.state && location.state.setQuestionTabOn) {
      setDefaultTabKey('questions')
    }

    // return () => {
    //     setErrors(null);
    // }
  }, [])

  const handleTabChange = value => {
    if (action === 'edit') {
      setDefaultTabKey(value)
    }
    // setDefaultTabKey(value)
  }

  const handleSubmit = allFields => {
    const formData = new URLSearchParams()
    const body = {
      ...allFields,
      business_id: appliedBusiness?.key,
      campaign_type: 'feedback',
      description: allFields.description ? allFields.description : undefined,
      // way_of_targeting : {[allFields.way_of_targeting.key] : allFields.way_of_targeting.key ? allFields.way_of_targeting.value : undefined}
    }
    // console.log(body);
    Object.keys(body).forEach(key => {
      if (typeof body[key] !== 'object') {
        if (typeof body[key] !== 'undefined') {
          console.log(key, body[key])
          formData.append(key, body[key])
        }
      } else {
        Object.keys(body[key]).forEach(obj_key => {
          if (Array.isArray(body[key][obj_key])) {
            body[key][obj_key].forEach(arr_key => {
              if (typeof arr_key !== 'undefined') {
                console.log(`${key}[${obj_key}]`, arr_key)
                formData.append(`${key}[${obj_key}]`, arr_key)
              }
            })
          } else {
            if (typeof body[key][obj_key] !== 'undefined') {
              formData.append(`${key}[${obj_key}]`, body[key][obj_key])
              console.log(`${key}[${obj_key}]`, body[key][obj_key])
            }
          }
        })
      }
    })
    setSubmitting(true)
    if (action === 'edit') {
      dispatch(
        editFeedback({
          body: formData,
          id: feedbackId,
          successCallback: handleEditSubmitSuccess,
          finalCallback: _ => setSubmitting(false),
        }),
      )
    } else {
      dispatch(
        createFeedback({
          body: formData,
          successCallback: handleCreateSubmitSuccess,
          finalCallback: _ => setSubmitting(false),
        }),
      )
    }
    // console.log(allFields)
  }

  const handleCreateSubmitSuccess = (feedback_id, status) => {
    setSubmitting(false)
    setFeedbackId(feedback_id)
    if (status === 'draft') {
      setDefaultTabKey('questions')
    } else {
      history.push('/feedback')
    }
  }

  const handleEditSubmitSuccess = (id, status) => {
    setSubmitting(false)
    setFeedbackId(id)
    if (status === 'draft') {
      setDefaultTabKey('questions')
    } else {
      history.push('/feedback')
    }
    // history.goBack()
  }

  const { TabPane } = Tabs

  return (
    <Tabs defaultActiveKey={defaultTabKey} activeKey={defaultTabKey} onChange={handleTabChange}>
      <TabPane key="fields" tab={`${action.toUpperCase()} FEEDBACK`}>
        <Fields initialValues={fields} handleSubmit={handleSubmit} loading={submtting} />
      </TabPane>
      <TabPane key="questions" tab={`${action.toUpperCase()} QUESTIONS`}>
        {action === 'edit' ? (
          <Edit includeId={feedbackId} includeType="survey" allQuestions={fields?.questions} />
        ) : (
          <Create includeId={feedbackId} includeType="survey" />
        )}
      </TabPane>
    </Tabs>
  )
}

export default connect()(withRouter(FeedbackForm))
