import React, { Component } from 'react'
import { Form, Input, Button, Checkbox } from 'antd'
import queryString from 'query-string'
import { FacebookFilled } from '@ant-design/icons'
import { Helmet } from 'react-helmet'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { login, register, socialLogin } from 'services/user'
import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login'
import styles from './style.module.scss'

@Form.create()
@connect(({ user }) => ({ user }))
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      requesting: false,
      passedProps: props?.location?.state,
      locSearch: queryString.parse(props?.location?.search),
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {}
  }

  handleSuccessCallback = val => {
    const { history } = this.props
    const { locSearch } = this.state
    console.log('success callback here', val)
    if (locSearch?.redirectTo) {
      if (val?.data?.user?.updateInfo) {
        history.push(`/user/basic-details?redirectTo=${locSearch?.redirectTo}`)
      } else {
        history.push(locSearch?.redirectTo)
        return
      }
      return
    }
    if (val?.data?.user?.type === 'customer') {
      history.push('/please-scan')
      return
    }
    history.push('/dashboard')

    this.setState({ requesting: false })
  }

  onSubmit = event => {
    event.preventDefault()
    const { form, dispatch } = this.props
    form.validateFields((error, values) => {
      if (!error) {
        const body = {
          email: values.email,
          password: values.password,
        }
        this.setState({ requesting: true })
        dispatch(
          login({
            body,
            successCallback: this.handleSuccessCallback,
            finalCallback: () => this.setRequestingFalse(),
          }),
        )
      }
    })
  }

  setRequestingFalse = () => {
    this.setState({ requesting: false })
  }

  setRequestingTrue = () => {
    this.setState({ requesting: true })
  }

  handleGoogleLoginSuccess = resp => {
    const { dispatch } = this.props
    const obj = {
      provider: 'google',
      access_token: resp.accessToken,
    }
    this.setState({ requesting: true })
    dispatch(
      socialLogin({
        obj,
        successCallback: this.handleSuccessCallback,
        finalCallback: () => this.setRequestingFalse(),
      }),
    )
  }

  handleResponseFacebook = resp => {
    const { dispatch } = this.props
    const obj = {
      provider: 'facebook',
      access_token: resp.accessToken,
    }
    this.setState({ requesting: true })
    dispatch(
      socialLogin({
        obj,
        successCallback: this.handleSuccessCallback,
        finalCallback: () => this.setRequestingFalse(),
      }),
    )
  }

  handleGoogleLoginFailure = resp => {
    console.info(resp)
  }

  render() {
    const {
      form,
      user: { loading },
      // location
    } = this.props
    const { requesting, locSearch } = this.state

    let registerUrl = '/user/register'
    if (locSearch?.redirectTo) {
      registerUrl = `${registerUrl}?redirectTo=${locSearch?.redirectTo}`
    }
    return (
      <div>
        <Helmet title="Login" />

        <div className={`${styles.block} authentication-form-wrapper authentication-form-wrapper--login`}>
          <div className={`${styles.title} login-heading`}>
            <h1>
              <strong>WELCOME TO POV</strong>
            </h1>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className={styles.inner}>
                <div className={styles.form}>
                  <h4 className="text-uppercase">
                    <strong>Please log in</strong>
                  </h4>
                  <br />
                  <Form layout="vertical" hideRequiredMark onSubmit={this.onSubmit}>
                    <Form.Item label="Email">
                      {form.getFieldDecorator('email', {
                        initialValue: 'test@user.com',
                        rules: [{ required: true, message: 'Please input your e-mail address' }],
                      })(<Input size="default" />)}
                    </Form.Item>
                    <Form.Item label="Password">
                      {form.getFieldDecorator('password', {
                        initialValue: '11111111',
                        rules: [{ required: true, message: 'Please input your password' }],
                      })(<Input size="default" type="password" />)}
                    </Form.Item>
                    <Form.Item>
                      {form.getFieldDecorator('remember', {
                        valuePropName: 'checked',
                        initialValue: true,
                      })(<Checkbox>Remember me</Checkbox>)}
                      <Link
                        to="/user/forgot"
                        className="utils__link--blue utils__link--underlined pull-right"
                      >
                        Forgot password?
                      </Link>
                    </Form.Item>
                    <div className="form-actions">
                      <Button
                        type="primary"
                        className="width-150 mr-4"
                        htmlType="submit"
                        loading={requesting}
                      >
                        Login
                      </Button>
                      <span className="ml-3 register-link">
                        <Link to={registerUrl} className="text-primary utils__link--underlined">
                          Register
                        </Link>{' '}
                        if you don&#39;t have account
                      </span>
                    </div>
                    <div className="form-group">
                      <p>Use another service to Log In</p>
                      <div className="mt-2">
                        <FacebookLogin
                          appId="641251690065848"
                          autoLoad={false}
                          fields="name,email,picture"
                          callback={this.handleResponseFacebook}
                          cssClass={styles.btnFacebook}
                          icon={<FacebookFilled style={{ marginRight: '5px' }} />}
                          textButton="Sign In with Facebook"
                        />
                        <GoogleLogin
                          clientId="706378787568-330c7kq1dlle28a9n9amfk9cl9p4cg0s.apps.googleusercontent.com"
                          cookiePolicy="single_host_origin"
                          onSuccess={this.handleGoogleLoginSuccess}
                          onFailure={this.handleGoogleLoginFailure}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Login)
