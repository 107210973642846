import React, { useState, useEffect } from 'react'
import { Icon, Avatar } from 'antd'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import logo from 'assets/app_logo.png'
import ProfileMenu from './ProfileMenu'
import RoleSelect from './RoleSelect'
import styles from './style.module.scss'

const TopBar = () => {
  const [isNotificationActive, setNotificationActive] = useState(false)
  const user = useSelector(state => state.user)

  const businessName = user?.business?.label
  let initials = businessName?.match(/\b\w/g) || []
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()

  useEffect(() => {
    const pathname = document.location.pathname.split('/')[2]
    if (pathname === 'notifications') {
      setNotificationActive(true)
      return
    }
    setNotificationActive(false)
  }, [])

  const [currentBusiness] = user?.access.filter(x => x.id === Number(user?.business?.key))

  return (
    <div className={styles.topbar}>
      <div>
        <Link to="/">
          <img src={logo} alt="POV-Logo" width="78px" />
        </Link>
      </div>
      <div className={`${styles.pov_divider}  ml-2`} />
      <div>
        {currentBusiness?.profile_pic ? (
          <Avatar src={currentBusiness?.profile_pic} alt={businessName} size="large" />
        ) : (
          <Avatar shape="square" size="large">
            {initials}
          </Avatar>
        )}
      </div>
      <div className=" ml-3">
        <RoleSelect size="default" />
      </div>
      <div className="ml-auto mr-4">
        <Link to="/notifications">
          <Icon
            type="bell"
            className="d-flex justify content center"
            style={{ fontSize: '20px', color: isNotificationActive ? 'blue' : 'grey' }}
            theme="filled"
          />
        </Link>
      </div>
      <div className=" mr-4">
        <Link to="/help">
          <Icon
            className="d-flex justify content center"
            type="question-circle"
            style={{ fontSize: '20px' }}
            theme="filled"
          />
        </Link>
      </div>
      <ProfileMenu />
    </div>
  )
}

export default TopBar
