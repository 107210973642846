import React from 'react';
import { Input, Rate, Select, InputNumber, DatePicker, Radio, Checkbox, TimePicker } from 'antd';
import AppRange from '../components/range';

const { TextArea } = Input;
const { Option } = Select;

export const getInput = (data) => {
  const { type, options, config, id } = data;

  switch (type?.toLowerCase()) {
    case 'textinput':
      return <Input />;

    case 'numberinput':
      return <InputNumber />;

    case 'textarea':
      return <TextArea
        rows={4} />;

    case 'checkboxes':
      let nOptions = []
      options.forEach((opt) => {
        nOptions.push({ label: opt?.text, value: opt?.value })
      })
      return <Checkbox.Group
        options={nOptions}
      />


    case 'radiobuttons':
      return <Radio.Group>
        {options?.map((opt, optIndex) => {
          return (
            <Radio
              value={opt?.value}
              key={optIndex?.toString()}>
              {opt?.text}
            </Radio>
          )
        })}
      </Radio.Group>;

    case 'datepicker':
      if (config?.showTimeSelect === "true" && config?.showTimeSelectOnly === 'true') {
        // 'true' is sent as a string instead of boolean in api
        return (
          <TimePicker
            format="HH:mm:ss"
          />
        )
      }
      return <DatePicker
        showTime={config?.showTimeSelect === "true" ? true : false}
      // format="YYYY-MM-DD HH:SS"
      />;

    case 'image':
      return <i>Image upload not available at the moment</i>

    case 'rating':
      return <Rate />

    case 'range':
      return <AppRange
        min={Number(config?.min_value)}
        max={Number(config?.max_value)}
        minLabel={config?.min_label}
        maxLabel={config?.max_label}
        default={Number(config?.default_value)}
      // onChange={handleOnChange}
      />

    case 'tags':
    case 'dropdown':
      return (
        <Select
          mode={type?.toLowerCase() === 'tags' ? 'tags' : 'default'}
          style={{ width: '100%' }}
          placeholder="Please select"
        >
          {options?.map((opt, optIndex) => {
            return (
              <Option
                key={optIndex?.toString()}
                value={opt?.value}
              >
                {opt?.label ?? opt?.text}
              </Option>
            )
          })}
        </Select>
      )

    case 'feedback_rating':
      return <AppRange
        min={0}
        max={100}
        minLabel="Angry"
        maxLabel="Happy"
        default={50}
      // onChange={handleOnChange}
      />

    default:
      return <i>Input Not available</i>

  }

}
