import React, { useEffect, useState } from 'react'
import { ReactFormBuilder, ElementStore } from 'react-form-builder2'
import { Button, Divider } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { createSurveyForm } from 'services/survey'
import { createFormFromApi } from 'utils/helperFunction'
import { createFormDataForQuestion } from 'utils/questionFormData'
import { Items } from './Items'
import 'react-form-builder2/dist/app.css'
import './builder.css'

const FormBuilderEdit = ({ dispatch, match, history, includeId, includeType, allQuestions }) => {
  const [data, setData] = useState([])
  const [questions, setQuestions] = useState([])
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (allQuestions?.length) {
      const validQuestions = createFormFromApi(allQuestions)
      setQuestions(validQuestions)
    }
  }, [allQuestions])

  const onChange = d => {
    if (d.length) {
      setData(d)
    } else {
      setData([])
    }
  }

  // const promisedData = async () => {
  //   if (questions.length) {
  //     const validQuestions = await createFormFromApi(allQuestions)
  //     // resolve(validQuestions)
  //     return validQuestions
  //   }
  // }

  useEffect(() => {
    ElementStore.subscribe(state => onChange(state.data))
  }, [includeId])

  const handleSurveyFormSuccessCallback = () => {
    setSubmitting(false)
    history.goBack()
  }

  const handleSubmit = _ => {
    const formData = createFormDataForQuestion({
      arr: data,
      includeType,
      includedOn: includeId || match.params.id,
    })
    setSubmitting(true)
    dispatch(
      createSurveyForm({
        body: formData,
        successCallback: handleSurveyFormSuccessCallback,
        finalCallback: () => setSubmitting(false),
      }),
    )
  }

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between">
        <h3>
          Edit questions for{' '}
          {history.location.pathname.split('/')[1] === 'survey-management' ? 'survey' : 'feedback'}
        </h3>
        <Button
          type="primary"
          className="pov-button-shadow"
          onClick={handleSubmit}
          loading={submitting}
        >
          Submit
        </Button>
      </div>
      <Divider />
      <div className="card-body">
        <ReactFormBuilder toolbarItems={Items} data={questions} />
      </div>
    </div>
  )
}

export default connect()(withRouter(FormBuilderEdit))
