export const createFormDataForQuestion = ({arr, includeType, includedOn}) => {
    const formData = new URLSearchParams()
    const all_questions = []
    if(arr.length){
        arr.forEach((element, index) => {
            let config
            if(element.element === 'Range'){
                config = {
                    max_label : element.max_label,
                    min_label : element.min_label,
                    max_value : parseInt(element.max_value, 10),
                    min_value : parseInt(element.min_value, 10),
                    step : parseInt(element.step, 10),
                    default_value : parseInt(element.default_value, 10)
                }
            }else if(element.element === 'DatePicker'){
                config = {
                    dateFormat : element.dateFormat,
                    defaultToday : element.defaultToday,
                    showTimeSelect : element.showTimeSelect,
                    showTimeSelectOnly : element.showTimeSelectOnly,
                    timeFormat : element.timeFormat
                }
            }
            // console.log('while sending', config);
            const ele = {
                type : element.element,
                question : element.label,
                is_required : element.required ? '1' : '0',
                options : element.options ? element.options : undefined,
                content : element.content,
                description : element.description,
                order : index + 1,
                id : element.id.match(/^[0-9]+$/) ? element.id : undefined,
                config : config ? config : undefined
            }
            all_questions.push(ele)
        })
    }
    if(all_questions.length){
      formData.append('include_type', includeType)
      formData.append('included_on', includedOn)
      all_questions.forEach((question, questionIndex) => {
        Object.keys(question).forEach((questionKey, questionKeyIndex) => {
          if(typeof question[questionKey] !== 'undefined' && typeof question[questionKey] !== 'object' && !Array.isArray(question[questionKey])){
            console.log(`questions[${questionIndex}][${questionKey}]`, question[questionKey])
            formData.append(`questions[${questionIndex}][${questionKey}]`, question[questionKey])
          }else if(typeof question[questionKey] === 'object' && !Array.isArray(question[questionKey])){
            Object.keys(question[questionKey]).forEach((objectKey, objectIndex) => {
              if(typeof question[questionKey][objectKey] !== 'undefined'){
                formData.append(`questions[${questionIndex}][${questionKey}][${objectKey}]`, question[questionKey][objectKey])
                console.log(`questions[${questionIndex}][${questionKey}][${objectKey}]`, question[questionKey][objectKey])
              }
            })
          }
          else if(Array.isArray(question[questionKey])){
            question[questionKey].forEach((arrayElement, arrayElementIndex) => {
              Object.keys(arrayElement).forEach((arrayObjectKey, arrayObjectIndex) => {
                if(typeof arrayElement[arrayObjectKey] !== 'undefined'){
                  formData.append(`questions[${questionIndex}][${questionKey}][${arrayElementIndex}][${arrayObjectKey}]`, arrayElement[arrayObjectKey])
                  console.log(`questions[${questionIndex}][${questionKey}][${arrayElementIndex}][${arrayObjectKey}]`, arrayElement[arrayObjectKey])
                }
              })
            })
          }
        })
      })
    }
    return formData
}