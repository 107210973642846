import actions from './actions'

const initialState = {
  id: '',
  name: '',
  role: '',
  email: '',
  avatar: '',
  authorized: false,
  loading: false,
  business : {},
  access : []
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_BUSINESS:
      return {
        ...state,
        business : action.payload
      }
    case actions.SET_ACCESS:
      // console.log('hello', action.payload[0])
      return{
        ...state,
        access : action.payload,
        // business : {key : localStorage.getItem('business_id') || action.payload[0]?.id, label : localStorage.getItem('business_name') || action.payload[0]?.name}
      }
    case actions.REMOVE_ACCESS:
      return {
        access : [],
        business : {}
      }
    default:
      return state
  }
}
