export const SET_OFFER_META = 'SET_OFFER_META'
export const SET_HAS_META = 'SET_HAS_META'
export const SET_OFFER_QUESTIONS = 'SET_OFFER_QUESTIONS'
export const SET_OFFER_DATA = 'SET_OFFER_DATA'
export const DELETE_OFFER = 'DELETE_OFFER'
export const UPDATE_OFFER = 'UPDATE_OFFER'
export const SET_OFFER_KEYWORD = 'SET_OFFER_KEYWORD'
export const SET_OFFER_BUSINESS = 'SET_OFFER_BUSINESS'
export const CLEAR_OFFER_SESSION = 'CLEAR_OFFER_SESSION'
export const SET_OFFER_DATE = 'SET_OFFER_DATE'
export const CREATE_AN_OFFER = 'CREATE_AN_OFFER'

export function setOfferData(payload){
    return {
        type : SET_OFFER_DATA,
        payload
    }
}

export function createAnOffer(payload){
    return {
        type : CREATE_AN_OFFER,
        payload
    }
}

export function updateOffer(payload){
    return{
        type : UPDATE_OFFER,
        payload
    }
}

export function setOfferMeta(meta){
    // console.log(meta);
    return {
        type : SET_OFFER_META,
        total : meta.total,
        currentPage : meta.currentPage,
        searchParams : meta.searchParams
    }
}

export function deleteOfferAction(id){
    return{
        type : DELETE_OFFER,
        payload : id
    }
}