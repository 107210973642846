import { SET_ERROR } from './action'

const initState = {
  errors: null,
}

export default function errorReducer(state = initState, action) {
  const { errors } = action

  if (SET_ERROR) {
    return {
      errors,
    }
  }

  return state
}
