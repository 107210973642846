export const CREATE_FOLLOWUP = 'CREATE_FOLLOWUP'
export const GET_FOLLOWUP = 'GET_FOLLOWUP'

export function createFollowUp(payload) {
  return {
    type: CREATE_FOLLOWUP,
    payload,
  }
}

export function getFollowUp(payload) {
  return {
    type: GET_FOLLOWUP,
    payload,
  }
}
