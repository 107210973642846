import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Descriptions, Form, Button, Spin, message, Result, PageHeader } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import parse from 'html-react-parser';

import { getInput } from '../../../utils/inputTypes';
import { getSurvey } from '../../../services/survey';
import { singleSurveyResponseApi } from '../../../services/surveyResponse';

import PovImg from '../../../assets/app_logo.png';

const imgConst = "https://lh6.googleusercontent.com/Hloa2aDVBZmp_XSmo8-qtKEMbYYHT6XTpDz2XbuX2JsdT42DWFc92kt8nj0ukEyoh2KxOzHZK9MDCnx9AWY0tFWbrivx5BNB6y33xLr8kh4TzCyII1BsHDJDnd1Gop5_YQ=w1200";

const SurveyPage = ({ form, isFeedback }) => {
  const [data, setData] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const state = useSelector(state => state);
  const { name } = state.user
  const { getFieldDecorator, validateFieldsAndScroll } = form;
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams();
  let surveyId = params?.surveyId
  let userToken = localStorage.getItem('povToken');
  let isFeedbackN = data?.campaign_type === 'feedback';

  const logout = () => {
    localStorage.removeItem('povToken')
    localStorage.removeItem('business_name')
    localStorage.removeItem('business_id')
    // setToken('')
    dispatch({
      type: 'user/LOGOUT',
    })
    dispatch({ type: 'REMOVE_ACCESS' })
    history.push('/user/login')
  }

  const fetchSurvey = () => {
    setLoading(true)
    dispatch(getSurvey({
      id: surveyId,
      callback: (val) => {
        setLoading(false)
        setData(val)
      },
      count: true
    }))
  }

  useEffect(() => {
    if (userToken) {
      if (surveyId) {
        fetchSurvey()
      }
    } else {
      history.push(`/user/login?redirectTo=${history?.location?.pathname}`)
    }
  }, [surveyId])


  const handleSubmit = (e) => {
    e.preventDefault()
    validateFieldsAndScroll((err, value) => {
      if (!err) {
        let responseArray = []
        Object.keys(value).forEach((key) => {
          if (key === 'feedback_rating') {
            return
          }
          if (value[key]) {
            responseArray.push({
              question_id: key,
              answer: value[key]
            })
          }
        })
        setSubmitting(true)
        let subObj = {
          survey_id: data?.id,
          responses: responseArray
        }

        if (isFeedbackN) {
          subObj = {
            ...subObj,
            rating: value?.feedback_rating?.toString()
          }
        }
        dispatch(singleSurveyResponseApi({
          object: subObj,
          successCallback: (response) => {
            message.success(response?.message);
            form.resetFields();
            setSubmissionSuccess(true);
          },
          failureCallback: (response) => {
            message.error(response?.message)
          },
          finalCallback: () => {
            setSubmitting(false)
          },
          questions: data?.questions
        }))
      }
    })
  }

  if (loading) {
    return (
      <Col
        xs={{ span: 20, offset: 2 }}
        lg={{ span: 16, offset: 4 }}
      >
        <div className="spinner">
          <Spin />
        </div>
      </Col>
    )
  }
  if (submissionSuccess) {
    return (
      <Result
        status="success"
        title="Successfully Submitted."
        subTitle="Thank you for your valuable time"
        extra={[
          <>
            {data?.update_response ?
              <Button key="buy" onClick={() => { setSubmissionSuccess() }}>Answer again</Button>
              :
              <></>}
          </>,
        ]}
      />
    )
  }

  return (
    <>
      <PageHeader
        style={{
          border: '1px solid rgb(235, 237, 240)',
        }}
        title={<img src={PovImg} className="pov-icon-survey" alt="pov-logo" />}
        extra={[<>
          <span>Logged in as <strong>{name}</strong></span>
          <Button onClick={() => logout()} type="link">
            LogOut
          </Button>
        </>]}
      />
      <div className="survey-container">

        <Row justify='center'>
          <Col
            xs={{ span: 20, offset: 2 }}
            lg={{ span: 16, offset: 4 }}
          >
            <div className="banner-wrapper">
              <img
                src={data?.cover_landscape ?? imgConst}
                alt="banner"
              />
            </div>
            <div className="section survey-details">
              <Card>
                <h2>
                  {parse(data?.title ?? '')}
                </h2>
                {data?.description &&
                  <p>{parse(data?.description)}</p>
                }
                <Descriptions>
                  <Descriptions.Item label="By">{data?.business?.name}</Descriptions.Item>
                  <Descriptions.Item label="Category">{data?.business?.category?.name ?? <i>Category N/A</i>}</Descriptions.Item>
                  <Descriptions.Item label="Campaign Type">{_.upperFirst(data?.campaign_type)}</Descriptions.Item>
                  {/* <Descriptions.Item label="Reward Points">{data
                  ?.reward_pov_coin}</Descriptions.Item>
                <Descriptions.Item label="Reward Stars">{data?.reward_stars}</Descriptions.Item> */}
                  <Descriptions.Item label="Start Date">{data?.starts_from ? moment(data?.starts_from).format('YYYY-MM-DD') : <i>Start Date N/A</i>}</Descriptions.Item>
                  <Descriptions.Item label="Ends Date">{data?.ends_at ? moment(data?.ends_at).format('YYYY-MM-DD') : <i>End Date N/A</i>}</Descriptions.Item>
                </Descriptions>
              </Card>
            </div>
            <Form
              name="survey_form"
              onSubmit={(e) => {
                handleSubmit(e)
              }}
            >

              {(data?.campaign_type === 'feedback') &&
                <div className="section question-container">
                  <Card>
                    <h3>
                      {/* Feedback rating */}
                    </h3>
                    <div className="input-component">
                      <Form.Item name='feedback_rating'>
                        {getFieldDecorator('feedback_rating',
                          {
                            rules: [{ required: true, message: 'This field is required!' }],
                          }
                        )(
                          getInput({
                            type: 'feedback_rating'
                          })
                        )}
                      </Form.Item>
                    </div>
                  </Card>
                </div>
              }
              <div className="section survey-questions">
                {
                  data?.questions?.length === 0 ?
                    <i>Questions not available for this {isFeedbackN ? 'feedback' : 'survey'}</i>
                    :
                    data?.questions?.map((question, questionIndex) => {
                      return (
                        <div className="section question-container" key={questionIndex?.toString()}>
                          <Card>
                            <h3>
                              {parse(question?.question)}{question?.is_required ? "*" : ""}
                            </h3>
                            <div className="input-component">
                              <Form.Item name={question?.id?.toString()}>
                                {getFieldDecorator(question?.id?.toString(),
                                  {
                                    rules: [{ required: question?.is_required ? true : false, message: 'This field is required!' }],
                                  }
                                )(
                                  getInput(question)
                                )}
                              </Form.Item>
                            </div>
                          </Card>
                        </div>
                      )
                    })
                }
              </div>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={submitting}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  )
}

const formWrapper = Form.create({ name: 'survey_form' })(SurveyPage)

export default formWrapper;
