import React, { useState } from 'react'
import { Button, Checkbox, Icon } from 'antd'
import moment from 'moment'
import CustomModal from 'components/CustomModal/CustomModal'
import feedbackSvg from 'assets/feedback.svg'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { downloadQrCode, changeStatus } from 'services/survey'
import { changeAdStatus } from 'services/ad'
import styles from './style.module.scss'

const Information = ({ title, value, icon }) => {
  return (
    <div className="d-flex align-items-center">
      <span className="material-icons">{icon}</span>
      <div className="mx-2">
        <p className="mb-0 font-weight-bold">{value}</p>
        <p className="text-muted font-weight-bold">{title}</p>
      </div>
    </div>
  )
}

const InformationCard = ({ history, feedback, coinSpent, match, data }) => {
  const { created_at, updated_at, published_at, creator } = data || {}
  const [visibleSaveModal, setVisibleSaveModal] = useState(false)
  const [page] = useState(history.location.pathname.split('/')[1])
  const [id] = useState(match.params.survey_id || match.params.feedback_id || match.params.ad_id)
  const [loadingQrCode, setLoadingQrCode] = useState(false)

  const dispatch = useDispatch()
  const closeSaveModal = () => {
    setVisibleSaveModal(false)
  }
  const openSaveModal = () => {
    setVisibleSaveModal(true)
  }

  const handleDownloadQRCode = () => {
    setLoadingQrCode(true)
    dispatch(downloadQrCode({ id, finalCallback: () => setLoadingQrCode(false) }))
  }

  const requestForPublish = () => {
    const payload = {
      status: 'pending_approval',
    }

    if (page === 'ad-management') {
      dispatch(
        changeAdStatus({
          body: payload,
          id,
          finalCallback: () => setVisibleSaveModal(false),
        }),
      )
    } else {
      dispatch(
        changeStatus({
          body: payload,
          id,
          type: data.campaign_type,
          finalCallback: () => setVisibleSaveModal(false),
        }),
      )
    }
  }
  return (
    <div className="border shadow p-3 bg-white">
      <div className="d-flex justify-content-around border-bottom">
        <div className="d-flex flex-column justify-content-center">
          <p className="text-center mb-0">
            <span className="material-icons px-2" style={{ position: 'relative', top: '5px' }}>
              supervisor_account
            </span>
            <span style={{ fontSize: '18px' }} className="font-weight-bold">
              {feedback || '500'}
            </span>
          </p>
          <p className="font-weight-bold">Total Feedback Received</p>
        </div>
        <div>
          <p className="text-center mb-1">
            <span
              className="fas fa-coins px-2"
              style={{ fontSize: '22px', position: 'relative', top: '5px' }}
            ></span>
            <span style={{ fontSize: '18px' }} className="font-weight-bold">
              {coinSpent || '5000'}
            </span>
          </p>
          <p className="font-weight-bold">Total POV Coin Spent</p>
        </div>
      </div>
      <div className="row p-3">
        <div className="col-6 border-right">
          <h6 className="text-center mb-3 font-weight-bold">Date and Creator</h6>
          <div className="row">
            <div className="col-lg-6 p-0">
              <Information
                title="Created On"
                value={(created_at && moment(created_at).format('MM-DD-YYYY')) || 'n/a'}
                icon="calendar_today"
              />
            </div>
            <div className="col-lg-6 p-0">
              <Information
                title="Created By"
                value={creator?.name || 'n/a'}
                icon="account_circle"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 p-0">
              <Information
                title="Modified On"
                value={(updated_at && moment(updated_at).format('MM-DD-YYYY')) || 'n/a'}
                icon="calendar_today"
              />
            </div>
            <div className="col-lg-6 p-0">
              <Information
                title="Active Since"
                value={(published_at && moment(published_at).format('MM-DD-YYYY')) || 'n/a'}
                icon="calendar_today"
              />
            </div>
          </div>
          <div className={`${styles.action_button} d-flex justify-content-center`}>
            <Button>Activity Log</Button>
          </div>
        </div>
        <div className="col-6">
          <h6 className="text-center font-weight-bold">Action Button</h6>
          <div
            className={`${styles.action_button} d-flex flex-column justify-content-center align-items-center`}
          >
            <Button
              className="my-2"
              size="large"
              // disabled={data?.status === 'pending_approval'}
              onClick={openSaveModal}
              icon="edit"
            >
              Request To Publish
            </Button>
            <CustomModal
              isModalOpen={visibleSaveModal}
              onOk={requestForPublish}
              onCancel={closeSaveModal}
              okText="Request For Publish"
              cancelText="Cancel for Now"
              onModalCancel={closeSaveModal}
              icon={feedbackSvg}
              title="Reviewing your Request"
              info={`we're reviewing your info we're reviewing your info to verify your Feedback Information and Confirming the information you entered matches public records for your business or organization. We'll send you a notification and email within a few hours`}
            >
              <div>
                <p>
                  <Checkbox /> By Clicking Request For Publish, you are agreeing to our terms and
                  condition
                </p>
              </div>
            </CustomModal>
            <Button
              className="my-3"
              size="large"
              onClick={handleDownloadQRCode}
              loading={loadingQrCode}
              icon="qrcode"
            >
              Download QR code
            </Button>
            <p className="d-flex align-items-center mt-4 mb-n3">
              <span className="material-icons outline">info</span>
              <span className="mx-2">
                It normally takes 1-2 working hour to accept your request
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(InformationCard)
