import { updateArr } from 'utils/helperFunction'
import * as actions from './action'

const initialStore = {
  data: [],
  total: undefined,
  keyword: undefined,
  business: undefined,
  currentPage: 1,
  from_date: undefined,
  to_date: undefined,
  searchParams: {},
  shouldAddQuestionOnSubmit: true,
  allAdCount: 0

}

export default function adReducer(state = initialStore, action) {
  switch (action.type) {
    case actions.SET_AD_META:
      return {
        ...state,
        total: action.total,
        currentPage: action.currentPage,
        searchParams: action.searchParams,
        allAdCount: action?.isAllAd ? action?.total : state?.allAdCount
      }
    case actions.SET_AD_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case actions.DELETE_AD:
      const withoutDeleted = state.data.filter(ad => ad.id !== action.payload)
      return {
        ...state,
        data: withoutDeleted,
      }
    case actions.CREATE_A_AD:
      const newData = [action.payload, ...state.data]
      return {
        data: newData,
      }
    case actions.UPDATE_AD:
      const updated_ad = updateArr(state.data, action.payload)
      // console.log(updated_ad);
      return {
        ...state,
        data: updated_ad || [],
      }
    case actions.SET_AD_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
      }
    case actions.SET_AD_BUSINESS:
      return {
        ...state,
        business: action.payload,
      }
    case actions.SET_AD_DATE:
      return {
        ...state,
        from_date: action.from_date,
        to_date: action.to_date,
      }
    case actions.CLEAR_AD_SESSION:
      return {
        ...state,
        business: undefined,
        keyword: undefined,
        currentPage: 1,
      }
    case actions.UPDATE_AD_STATUS:
      const newState = state.data.map(obj =>
        obj.id === action.payload.id
          ? { ...obj, active: action.payload.active, status: action.payload.status }
          : obj,
      )
      return {
        ...state,
        data: newState,
      }
    case actions.SHOULD_ADD_QUESTION:
      return {
        ...state,
        shouldAddQuestionOnSubmit: action.payload,
      }
    default:
      return state
  }
}
