import React from 'react'
import { Button } from 'antd'
import placeholder from 'assets/images/logo-placeholder.png'

const BusinessDetailCard = ({ name, address, coins, feedback, duration, image }) => {
  return (
    <div className="border shadow bg-white" style={{ borderRadius: '7px' }}>
      <div
        style={{ backgroundColor: '#77899a', color: '#ffffff', borderRadius: '7px 7px 0px 0px' }}
        className="p-1"
      >
        <img
          src={image || placeholder}
          alt="business"
          style={{ borderRadius: '80px', width: '50px', height: '50px' }}
          className="d-flex mx-auto my-2"
        />
        <p className="text-center mb-0 mt-0 font-weight-bold">{name || 'Business Name'}</p>
        <div className="text-center  mb-1">
          <p className="d-flex justify-content-center mb-0">
            <span className="material-icons font-size-14">location_on</span>
            <span className="my-n1">{address || 'n/a'}</span>
          </p>
        </div>
      </div>
      <div className="row m-1 p-2 bg-white no-gutters">
        <div className="col text-center p-0">
          <i className="fas fa-coins" style={{ fontSize: '24px' }}></i>
          <p className="mb-0 font-weight-bold text-muted" style={{ paddingTop: '3px' }}>
            Up to
          </p>
          <p className="font-weight-bold">{`${coins || '50 '} POV Coins`}</p>
        </div>
        <div className="col p-0 text-center">
          <span className="material-icons">supervisor_account</span>
          <p className="mb-0 font-weight-bold text-muted">Feedback Received</p>
          <p className="font-weight-bold">{feedback || '500'}</p>
        </div>
        <div className="col p-0 text-center">
          <span className="material-icons">access_time</span>
          <p className="mb-0 font-weight-bold text-muted">DURATION</p>
          <p className="font-weight-bold">{`${duration || '1-2'} minutes`}</p>
        </div>
      </div>
      <div className="bg-white mt-n1">
        <h5 className="text-center mb-0 font-weight-bold">Share Your Opinion and Earn</h5>
        <p className="text-center mb-0">Earn points by answering surveys</p>
        <div className="d-flex justify-content-center">
          <Button type="primary" className="my-3">
            Participate In Survey
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BusinessDetailCard
