import React from 'react'
import { Route, Switch, Redirect, BrowserRouter, Link } from 'react-router-dom'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'

// import Can from 'config/can'
import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'
import { getCurrentUser } from 'services/user'
import Form from 'components/FeedbackComponents/Form'
import SurveyPage from './pages/front/survey';
import BasicDetails from './pages/user/basicDetails';
import Login from './pages/user/login';
import PleaseScan from './pages/pleaseScan';

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // System Pages
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },
  {
    path: '/user/password/reset',
    component: loadable(() => import('pages/user/resetPassword')),
    exact: true,
  },
  {
    path: '/user/register',
    component: loadable(() => import('pages/user/register')),
    exact: true,
  },
  // {
  //   path: '/user/basic-details',
  //   component: loadable(() => import('pages/user/basicDetails')),
  //   exact: true
  // },
  {
    path: '/admin/users',
    component: loadable(() => import('components/AdminComponents/Users')),
    exact: true,
  },
  {
    path: '/profile/change-password',
    component: loadable(() => import('components/Profile/ChangePassword')),
    exact: true,
  },
  {
    path: '/profile/edit',
    component: loadable(() => import('pages/profile')),
    exact: true,
  },
  {
    path: '/admin/users/add',
    component: loadable(() => import('components/Users/UsersForm')),
    exact: true,
  },

  // {
  //   path: '/survey/:survey_id',
  //   component: loadable(() => import('pages/front/survey')),
  //   exact: true,
  //   isFront: true,
  // },


  // Dashboards
  {
    path: '/dashboard',
    component: loadable(() => import('pages/dashboard/alpha')),
    exact: true,
  },

  {
    path: '/feedback',
    component: loadable(() => import('pages/feedback')),
    exact: true,
  },
  {
    path: '/feedback/responses/:feedback_id',
    component: loadable(() => import('components/FeedbackComponents/Detail')),
    exact: true,
  },
  {
    path: '/feedback/response/:survey_response_id',
    component: loadable(() => import('components/SurveyComponents/Response')),
    exact: true,
  },
  {
    path: '/feedback/detail/:feedback_id',
    component: loadable(() => import('components/FeedbackComponents/Detail')),
    exact: true,
  },
  {
    path: '/feedback/edit/:feedback_id',
    component: loadable(() => import('components/FeedbackComponents/Detail')),
    exact: true,
  },
  {
    path: '/feedback/:action/',
    // component: loadable(() => import('components/FeedbackComponents/Form')),
    component: Form,
    exact: true,
  },
  // {
  //   path: '/feedback/:action/:id',
  //   component: loadable(() => import('components/FeedbackComponents/Form')),
  //   // component : Form,
  //   exact: true,
  // },
  {
    path: '/activity',
    component: loadable(() => import('pages/activity')),
    exact: true,
  },
  {
    path: '/survey-management',
    component: loadable(() => import('pages/surveyManagement')),
    // component : Page,
    exact: true,
  },
  {
    path: '/survey-management/responses/:survey_id',
    component: loadable(() => import('components/SurveyComponents/Detail')),
    exact: true,
  },
  {
    path: '/survey-management/response/:survey_response_id',
    component: loadable(() => import('components/SurveyComponents/Response')),
  },
  {
    path: '/survey-management/detail/:survey_id',
    component: loadable(() => import('components/SurveyComponents/Detail')),
    exact: true,
  },
  // {
  //   path: '/survey-management/:action/',
  //   component: loadable(() => import('components/SurveyComponents/Form')),
  //   exact: true,
  // },
  // {
  //   path: '/survey-management/detail/:id',
  //   component: loadable(() => import('components/SurveyComponents/Form')),
  //   exact: true,
  // },
  {
    path: '/survey-management/edit/:survey_id',
    component: loadable(() => import('components/SurveyComponents/Detail')),
    exact: true,
  },
  {
    path: '/survey-management/add/:survey_id/questions',
    component: loadable(() => import('components/Question/Create')),
    exact: true,
  },
  {
    path: '/survey-management/edit/:survey_id/questions',
    component: loadable(() => import('components/Question/Edit')),
    exact: true,
  },
  {
    path: '/ad-management',
    component: loadable(() => import('pages/adsManagement')),
    exact: true,
  },
  {
    path: '/ad-management/detail/:ad_id',
    component: loadable(() => import('components/AdComponents/Detail')),
    exact: true,
  },
  {
    path: '/ad-management/:action',
    component: loadable(() => import('components/AdComponents/Form')),
    // component : Form,
    exact: true,
  },
  {
    path: '/offers',
    component: loadable(() => import('pages/offers')),
    exact: true,
  },
  {
    path: '/offers/:action',
    component: loadable(() => import('components/OffersComponents/Form')),
    exact: true,
  },
  {
    path: '/offers/:action/:id',
    component: loadable(() => import('components/OffersComponents/Form')),
    exact: true,
  },
  {
    path: '/ad-management/:action/:ad_id',
    component: loadable(() => import('components/AdComponents/Form')),
    exact: true,
  },
  {
    path: '/admin/business',
    component: loadable(() => import('components/AdminComponents/Business')),
    exact: true,
  },
  {
    path: '/admin/business/:action/:id?',
    component: loadable(() => import('components/BusinessComponents/Form')),
    // component : Form,
    exact: true,
  },
  {
    path: '/category',
    component: loadable(() => import('pages/category')),
    exact: true,
  },
  {
    path: '/category/:action/:id?',
    component: loadable(() => import('components/CategoryComponents/Form')),
    exact: true,
  },

  {
    path: '/admin',
    component: loadable(() => import('pages/adminConsole/AdminPage')),
    exact: true,
  },
  {
    path: '/admin/general',
    component: loadable(() => import('components/AdminComponents/General')),
    exact: true,
  },
  {
    path: '/admin/general/edit',
    component: loadable(() => import('components/BusinessComponents/Form')),
    exact: true,
  },
  {
    path: '/admin/user',
    component: loadable(() => import('components/AdminComponents/Role')),
    exact: true,
  },
  {
    path: '/admin/branches',
    component: loadable(() => import('components/AdminComponents/Branch')),
    exact: true,
  },
  {
    path: '/admin/branches/:action/:id?',
    component: loadable(() => import('components/BusinessComponents/Form')),
    exact: true,
  },
  {
    path: '/admin/wallets',
    component: loadable(() => import('components/AdminComponents/Wallet/')),
    exact: true,
  },
  {
    path: '/admin/reports',
    component: loadable(() => import('components/AdminComponents/Reports')),
    exact: true,
  },
  {
    path: '/admin/reports/verify/l1',
    component: loadable(() => import('components/AdminComponents/Reports/ReportLock')),
    exact: true,
  },
  {
    path: '/admin/reports/verify/l2',
    component: loadable(() => import('components/AdminComponents/Reports/ReportLock')),
    exact: true,
  },
  {
    path: '/admin/reports/verify/l3',
    component: loadable(() => import('components/AdminComponents/Reports/ReportLock')),
    exact: true,
  },
  {
    path: '/admin/reports/l1',
    component: loadable(() => import('components/AdminComponents/Reports/L1/L1Reports.js')),
    exact: true,
  },
  {
    path: '/admin/reports/l2',
    component: loadable(() => import('components/AdminComponents/Reports/L2/L2Reports.js')),
    exact: true,
  },
  {
    path: '/admin/reports/l3',
    component: loadable(() => import('components/AdminComponents/Reports/L3/L3Reports.js')),
    exact: true,
  },
  // {
  //   path: '/activity',
  //   component: loadable(() => import('pages/activity')),
  //   exact: true,
  // },
  {
    path: '/activity/:action',
    component: loadable(() => import('components/Activity/Form')),
    exact: true,
  },
  {
    path: '/activity/:action/:id',
    component: loadable(() => import('components/Activity/Form')),
    exact: true,
  },
  {
    path: '/notifications',
    component: loadable(() => import('pages/notifications')),
    exact: true,
  },
]

class Router extends React.Component {
  componentDidMount() {
    const { dispatch, id, type } = this.props
    if (!id || id === '') {
      dispatch(getCurrentUser())
    }
  }

  render() {
    const { type } = this.props
    return (
      <BrowserRouter basename="/">
        <Switch>
          <Route
            exact
            path="/form/survey/:surveyId"
            render={() => {
              return <SurveyPage />
            }}
          />
          <Route
            exact
            path="/form/feedback/:surveyId"
            render={() => {
              return <SurveyPage isFeedback />
            }}
          />
          <Route
            exact
            path="/user/basic-details"
            render={() => {
              return <BasicDetails isFeedback />
            }}
          />
          <Route
            exact
            path="/please-scan"
            render={() => {
              return <PleaseScan />
            }}
          />

          <Route
            exact
            path="/"
            render={() => {
              if (localStorage.getItem('povToken')) {
                return <Redirect to="/dashboard" />
              }
              return <Redirect to="/user/login" />
            }}
          />

          {type === 'customer' ?
            <div className="no-access-container">You donot have privilage to access this page</div>
            :
            <IndexLayout>
              {routes.map(route => {
                return (
                  <Route
                    path={route.path}
                    component={route.component}
                    key={route.path}
                    exact={route.exact}
                  />
                )
              }
              )}
            </IndexLayout>
          }
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = state => {
  return {
    id: state.user.id,
    type: state.user.type
  }
}

export default connect(mapStateToProps)(Router)
