export const SET_FEEDBACK_META = 'SET_FEEDBACK_META'
export const SET_HAS_META = 'SET_HAS_META'
export const SET_FEEDBACK_QUESTIONS = 'SET_FEEDBACK_QUESTIONS'
export const SET_FEEDBACK_DATA = 'SET_FEEDBACK_DATA'
export const DELETE_FEEDBACK = 'DELETE_FEEDBACK'
export const UPDATE_FEEDBACK = 'UPDATE_FEEDBACK'
export const SET_FEEDBACK_KEYWORD = 'SET_FEEDBACK_KEYWORD'
export const SET_FEEDBACK_BUSINESS = 'SET_FEEDBACK_BUSINESS'
export const CLEAR_FEEDBACK_SESSION = 'CLEAR_FEEDBACK_SESSION'
export const SET_FEEDBACK_DATE = 'SET_FEEDBACK_DATE'
export const CREATE_A_FEEDBACK = 'CREATE_A_FEEDBACK'
export const SHOULD_ADD_QUESTION = 'SHOULD_ADD_QUESTION'
export const UPDATE_FEEDBACK_STATUS = 'UPDATE_FEEDBACK_STATUS'

export function setFeedbackData(payload) {
  return {
    type: SET_FEEDBACK_DATA,
    payload,
  }
}

export function createAFeedback(payload) {
  return {
    type: CREATE_A_FEEDBACK,
    payload,
  }
}

export function updateFeedback(payload) {
  return {
    type: UPDATE_FEEDBACK,
    payload,
  }
}

export function setFeedbackMeta(meta) {
  // console.log(meta);
  return {
    type: SET_FEEDBACK_META,
    total: meta.total,
    currentPage: meta.currentPage,
    searchParams: meta.searchParams,
    isAllFeedback: meta?.isAllFeedback
  }
}

export function deleteFeedbackAction(id) {
  return {
    type: DELETE_FEEDBACK,
    payload: id,
  }
}

export function setFeedbackKeyword(keyword) {
  console.log(keyword)
  return {
    type: SET_FEEDBACK_KEYWORD,
    payload: keyword,
  }
}

export function setFeedbackBusiness(business) {
  return {
    type: SET_FEEDBACK_BUSINESS,
    payload: business,
  }
}

export function clearFeedbackSession() {
  return {
    type: CLEAR_FEEDBACK_SESSION,
  }
}

export function setFeedbackDate(date) {
  return {
    type: SET_FEEDBACK_DATE,
    from_date: date.from,
    to_date: date.to,
  }
}

export function shouldAddQuestion(value) {
  return {
    type: SHOULD_ADD_QUESTION,
    payload: value,
  }
}
export function updateFeedbackStatus(payload) {
  return {
    type: UPDATE_FEEDBACK_STATUS,
    payload,
  }
}
