import { notification, message } from 'antd'
import { genericApiCall, setToken, getApiCall } from 'helpers/apiCall'
import actions from 'redux/user/actions'
import { setErrors } from 'redux/errors/action'
import {
  baseUri,
  loginUrl,
  registerUrl,
  currentUserUrl,
  userUrl,
  forgotPasswordUrl,
  resetPasswordUrl,
  socialLoginUrl,
  urlWithParams,
  profileUrl,
  changePasswordUrl,
  basicDetailsUrl
} from 'helpers/urls'
import Axios from 'axios'

function getEncodedParams(body) {
  const params = new URLSearchParams()
  Object.keys(body).forEach(key => {
    if (body[key]) {
      params.append(key, body[key])
    }
  })
  return params
}

export function login({ body, successCallback, finalCallback }) {
  return dispatch => {
    const url = `${baseUri}/${loginUrl}`
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    const params = getEncodedParams(body)
    Axios.post(url, params, config)
      .then(resp => {
        const { token, user } = resp.data.data
        if (user?.email_verified_at) {
          localStorage.setItem('povToken', token)
          setToken(token)
          notification.success({
            message: 'Successful',
            description: `Successfully logged in as ${body.email}`,
          })
          dispatch({
            type: actions.SET_STATE,
            payload: { authorized: true, role: user.type, ...user },
          })
          if (typeof successCallback === 'function') {
            successCallback(resp.data)
          }
        } else {
          notification.info({
            message: 'Email not verified',
            description: `Please verify email first and try login.`,
          })
        }
      })
      .catch(err => {
        console.log(err)
        notification.error({
          message: 'Error',
          description: `Couldn't login. Credential didn't match our records`,
        })
      })
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export async function logout() {
  return firebaseAuth()
    .signOut()
    .then(() => true)
}

export function register({ body, successCallback, finalCallback }) {
  return dispatch => {
    const url = `${baseUri}/${registerUrl}`
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    const params = getEncodedParams(body)
    Axios.post(url, params, config)
      .then(resp => {
        const { token, user } = resp.data.data
        // localStorage.setItem('povToken', token)
        // setToken(token)
        if (token) {
          notification.success({
            message: 'User Registered',
            description: `Please login to continue further!`,
          })
        }

        // dispatch({
        //   type: actions.SET_STATE,
        //   payload: { authorized: true, role: user.type, ...user },
        // })
        if (typeof successCallback === 'function') {
          successCallback()
        }
      })
      .catch(err => {
        if (err.response) {
          const {
            response: { data },
          } = err
          dispatch(setErrors(data.data.errors))
        }
        notification.error({
          message: err.response.data.message,
          description: 'Something went wrong!!',
        })
      })
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}
export function getCurrentUser() {
  return dispatch => {
    const url = `${baseUri}/${currentUserUrl}`
    const token = localStorage.getItem('povToken')
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    try {
      if (token) {
        Axios.get(url, config).then(resp => {
          const { data } = resp.data
          console.info(data)
          localStorage.setItem('role', data.type)
          dispatch({
            type: actions.SET_STATE,
            payload: {
              authorized: true,
              role: data.type,
              ...data,
            },
          })
        })
      }
    } catch (err) {
      console.err(err)
    }
  }
}

export function forgotPassword({ body, successCallback, finalCallback }) {
  return dispatch => {
    const url = `${baseUri}/${forgotPasswordUrl}`
    genericApiCall({ method: 'POST', dispatch, body, url })
      .then(resp => {
        if (resp.data.success) {
          if (typeof successCallback === 'function') {
            successCallback()
          }
          notification.success({
            message: 'Password reset link sent',
            description: 'Please check your email for reset link.',
          })
        } else {
          console.log('reset link')
        }
      })
      .catch(err => {
        if (err.response) {
          const {
            response: { data },
          } = err
          dispatch(setErrors(data?.data?.errors))
        }
      })
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}
export function resetPassword({ body, successCallback, finalCallback }) {
  return dispatch => {
    const url = `${baseUri}/${resetPasswordUrl}`
    genericApiCall({ method: 'POST', dispatch, body, url })
      .then(resp => {
        if (resp.data.success) {
          if (typeof successCallback === 'function') {
            successCallback()
          }
          notification.success({
            message: 'Password reset successfully',
            description: 'Please login with your new password.',
          })
        } else {
          console.log('reset password')
        }
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 500) {
            notification.warning({
              message: err.response?.data?.message || 'Something went wrong!!',
            })
          }
          const {
            response: { data },
          } = err
          dispatch(setErrors(data?.data?.errors))
        }
      })
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function socialLogin({ obj, successCallback }) {
  return dispatch => {
    const url = `${baseUri}/${socialLoginUrl}`
    const params = getEncodedParams(obj)
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('povToken')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    Axios.post(url, params, config)
      .then(resp => {
        console.log(resp)
        const { token, user } = resp.data.data
        localStorage.setItem('povToken', token)
        setToken(token)
        notification.success({
          message: 'Successful',
          description: `Successfully logged in as ${user.name || user.email}`,
        })
        localStorage.setItem('role', user.type)
        // console.log('user', user.type)
        dispatch({
          type: actions.SET_STATE,
          payload: { authorized: true, role: user.type, ...user },
        })
        if (typeof successCallback === 'function') {
          successCallback(resp.data)
        }
      })
      .catch(err => console.log(err))
  }
}

export function getAllUser({ params, successCallback, finalCallback }) {
  return dispatch => {
    const url = urlWithParams(`${baseUri}/${userUrl}`, params)
    getApiCall({ dispatch, url })
      .then(resp => {
        if (resp.success && typeof successCallback === 'function') {
          successCallback(resp.data.data)
        }
      })
      .catch(err => console.log(err))
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function changePassword({ body, successCallback, finalCallback }) {
  return dispatch => {
    const url = `${baseUri}/${profileUrl}/${changePasswordUrl}`
    genericApiCall({ url, method: 'POST', body, dispatch })
      .then(resp => {
        console.info(resp.data)
        if (resp?.data?.success) {
          console.log(resp)
          message.success(resp?.data?.message)
          if (typeof successCallback === 'function') {
            successCallback()
          }
        }
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 500) {
            notification.warning({
              message: err.response?.data?.message || 'Something went wrong!!',
            })
          }
          const {
            response: { data },
          } = err
          dispatch(setErrors(data?.data?.errors))
        }
      })
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function updateProfile({ body, finalCallback, successCallback }) {
  return dispatch => {
    const url = `${baseUri}/${profileUrl}`
    genericApiCall({ url, body, dispatch, method: 'POST' })
      .then(resp => {
        console.log(resp)
        if (resp.data.success) {
          dispatch({
            type: actions.SET_STATE,
            payload: {
              authorized: true,
              // role: data.type,
              // business : {key : 21, label : 'Bajeko ko arko sekuwa'},
              ...resp.data.data,
            },
          })
          message.success(resp?.data?.message)
          if (typeof successCallback === 'function') {
            successCallback()
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}


export function updateBasicDetails({ body, finalCallback, successCallback }) {
  return dispatch => {
    const url = `${baseUri}/${basicDetailsUrl}`;
    genericApiCall({ url, body, dispatch, method: 'POST' })
      .then(response => {
        const { data } = response;
        console.log('update baisc details response here', response)
        message.success(data?.message)
        if (data?.success) {
          successCallback(data)
        }

      })
      .catch(err => console.log('error here', err))
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}
