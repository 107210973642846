/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Select, Spin, Empty } from 'antd'
// import CustomSelect from 'components/CommonComponents/CustomSelect'
import { urlWithParams, baseUri, businessUrl } from 'helpers/urls'
import { getApiCall } from 'helpers/apiCall'
import { connect } from 'react-redux'
import { CaretDownOutlined } from '@ant-design/icons'

import styles from '../style.module.scss'

const TopBusinessSelect = ({ data, business, dispatch, role, ...props }) => {
  const [loading, setLoading] = useState(false)

  const getAddress = (obj, key, value) => obj.find(v => v[key] === value).address

  const fetchBusiness = keyword => {
    const params = {
      key: keyword,
    }
    const url = urlWithParams(`${baseUri}/${businessUrl}/authorized`, params)
    getApiCall({ url, dispatch })
      .then(resp => {
        if (resp && resp.success) {
          dispatch({ type: 'SET_ACCESS', payload: resp.data.data })
        }
      })
      .catch(err => {
        dispatch({ type: 'REMOVE_ACCESS' })
      })
  }

  useEffect(() => {
    fetchBusiness('')
  }, [role])

  useEffect(() => {
    if (business && !Object.keys(business).length) {
      dispatch({
        type: 'user/SET_BUSINESS',
        payload: {
          key: localStorage.getItem('business_id'),
          label: localStorage.getItem('business_name'),
          address: localStorage.getItem('business_address'),
        },
      })
    }
  }, [business])

  useEffect(() => {
    if (!localStorage.getItem('business_id')) {
      console.log('setting default business if not present in localStroage')
      if (data?.length && !business?.key) {
        let address = data[0]?.address && Object.values(data[0]?.address).join(',')
        dispatch({ type: 'user/SET_BUSINESS', payload: { key: data[0]?.id, label: data[0]?.name } })
        localStorage.setItem('business_id', data[0]?.id)
        localStorage.setItem('business_name', data[0]?.name)
        localStorage.setItem('business_address', address)
      }
    }
  }, [data, business])

  // const handleSuccess = respData => {
  //     dispatch({type : 'SET_ACCESS', payload : respData})
  // }

  const handleSearch = searchedWord => {
    fetchBusiness(searchedWord)
  }

  const handleBusinessChange = selectedBusiness => {
    let address = Object.values(getAddress(data, 'id', selectedBusiness.key)).join(',');
    localStorage.setItem('business_id', selectedBusiness ? selectedBusiness.key : '')
    localStorage.setItem('business_name', selectedBusiness ? selectedBusiness.label : '')
    localStorage.setItem('business_address', address)
    dispatch({ type: 'user/SET_BUSINESS', payload: { ...selectedBusiness, address } })
  }

  return (
    <div className="d-flex flex-column">
      <span className={`mb-n3 mt-n5 font-size-10 font-weight-bold ${styles.ls9}`}>
        All Accounts
        {`> ${business?.label}`}
      </span>
      <Select
        // value={business.key ? { key: business.key, label: business.label } : undefined}
        value={{ key: 0, label: <span className="font-weight-bold">Switch Accounts</span> }}
        suffixIcon={<CaretDownOutlined />}
        labelInValue
        defaultActiveFirstOption
        filterOption={false}
        // notFoundContent={loading ? <Spin size="small" /> : <Empty />}
        onChange={handleBusinessChange}
        onSearch={handleSearch}
        loading={loading}
        style={{ width: 200 }}
        className="border 2 rounded-sm mt-n2"
        // allowClear
        showSearch
        {...props}
      >
        {(data || []).map(d => {
          return (
            <Select.Option key={d.id} value={d.id}>
              {d.name || d.title}
            </Select.Option>
          )
        })}
      </Select>
    </div>
  )
}

const mapStateToProps = state => {
  // console.log(state.user.data)
  return {
    data: state.user.access,
    business: state.user.business,
    role: state.user.role,
  }
}

export default connect(mapStateToProps)(TopBusinessSelect)
