import React, { useState, useEffect, useRef } from 'react'
import { Switch, InputNumber, DatePicker, Icon, Slider } from 'antd'
import { withRouter } from 'react-router-dom'
import FieldInfo from 'components/FormElements/FieldInfo'
import moment from 'moment'

const ForFeedback = ({ handleDateChange, checked, date, days, handleDaysChange, error }) => {
  return (
    <>
      <p className="font-weight-bold">Duration</p>
      <div className="d-flex flex-wrap justify-content-between border-bottom pb-3">
        <div>
          <InputNumber
            placeholder="Days"
            disabled={!checked}
            onChange={handleDaysChange}
            value={days}
            min={1}
          />
          <FieldInfo
            info={error?.duration ? error.duration[0] : undefined}
            className="text-danger"
          />
        </div>
        <div>
          <DatePicker disabled={!checked} onChange={handleDateChange} value={date} />
          <FieldInfo
            info={error?.budget_ends_at ? error.budget_ends_at[0] : undefined}
            className="text-danger"
          />
        </div>
      </div>
    </>
  )
}

const ForOthers = ({ perPerson, handlePerPerson, coin, checked, error }) => {
  return (
    <div className="d-flex justify-content-between mb-3 pt-3">
      <div>
        <p className="font-weight-bold">POV coins per participant</p>
        <InputNumber disabled={!checked} onChange={handlePerPerson} value={perPerson} min={1} />
        <FieldInfo
          info={error?.reward_pov_coin ? error?.reward_pov_coin[0] : undefined}
          className="text-danger"
        />
      </div>
      <div>
        <p className="font-weight-bold">Estimate Number of participant</p>
        <p>{parseInt(coin / perPerson, 10)} people will gain pov coin by giving feedback.</p>
      </div>
    </div>
  )
}

const CoinRewardComponent = ({ onChange, initialValues, disabled, location, error }) => {
  const [checked, setChecked] = useState()
  const [days, setDays] = useState(initialValues?.duration)
  const [date, setDate] = useState(
    initialValues?.budget_ends_at ? moment(initialValues?.budget_ends_at) : undefined,
  )
  const [coin, setCoin] = useState(initialValues?.budget || 5000)
  const [coinEdit, setCoinEdit] = useState(false)
  const [perPerson, setperPerson] = useState(initialValues?.reward_pov_coin || 1)
  const [page] = useState(location.pathname.split('/')[1])
  const [serverSideError, setError] = useState()

  // console.log(initialValues)

  useEffect(() => {
    setError(error)
  }, [error])

  const handleDisable = value => {
    setChecked(value)
  }

  const handleCoinChange = value => {
    setCoin(value)
  }

  const handleCoinEdit = () => {
    setCoinEdit(true)
  }

  const handleDateChange = value => {
    setDate(value)
  }

  const handleDaysChange = value => {
    setDays(value)
  }

  const handlePerPersonChange = value => {
    setperPerson(value)
  }

  useEffect(() => {
    if (initialValues?.budget) {
      setCoin(initialValues.budget)
    }

    if (initialValues?.reward_pov_coin) {
      setperPerson(initialValues.reward_pov_coin)
    }

    // if (initialValues?.valid_offer) {
    //   setChecked(initialValues?.valid_offer ? true : false)
    // }

    if (typeof initialValues?.budget_enabled === 'boolean') {
      setChecked(initialValues?.budget_enabled)
    }
    if (initialValues?.campaign_type === 'survey') {
      setChecked(true)
    }

    if (typeof initialValues?.budget_enabled === 'undefined') {
      setChecked(true)
    }

    if (disabled) {
      setChecked(false)
    }

    if (initialValues?.budget_ends_at) {
      setDate(moment(initialValues.budget_ends_at))
    }

    if (initialValues?.duration) {
      setDays(initialValues.duration)
    }
  }, [disabled, initialValues])

  useEffect(() => {
    onChange({ days, date, coin, checked, perPerson })
  }, [checked, days, date, coin, onChange, perPerson])

  // const {Item} = Form;
  // const {getFieldDecorator} = form

  return (
    <>
      <div className="d-flex justify-content-between">
        <h4 className="font-weight-bold">
          Coin Rewards <span className={`${page !== 'feedback' ? 'd-none' : ''}`}>(Optional)</span>
        </h4>
        <div className={`${page !== 'feedback' ? 'd-none' : ''}`}>
          <Switch defaultChecked checked={checked} onChange={handleDisable} disabled={disabled} />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="border-bottom">
            {page === 'feedback' ? (
              <ForFeedback
                days={days}
                handleDateChange={handleDateChange}
                handleDaysChange={handleDaysChange}
                checked={checked}
                date={date}
                error={serverSideError}
              />
            ) : null}
            <ForOthers
              handlePerPerson={handlePerPersonChange}
              perPerson={perPerson}
              coin={coin}
              checked={checked}
              error={serverSideError}
            />
          </div>
          <div className="my-3">
            <p className="font-weight-bold">Total Budget</p>
            <h4 className="text-center text-primary ">
              <span className="pt-2 px-1">
                <Icon type="gold" />
              </span>
              {/* <span style={{ fontSize: '35px' }}>{coin}</span> */}
              <InputNumber
                min={5000}
                max={1000000}
                disabled={!coinEdit}
                value={coin}
                onChange={handleCoinChange}
              />
              <span style={{ fontSize: '9px' }} className="px-1">
                POV coins
              </span>
              <span style={{ fontSize: '9px' }}>
                <Icon
                  type="edit"
                  role="button"
                  onClick={handleCoinEdit}
                  style={{ cursor: 'pointer' }}
                />
              </span>
            </h4>
            <div>
              <Slider
                defaultValue={5000}
                min={5000}
                max={1000000}
                step={1000}
                disabled={!checked}
                value={coin}
                onChange={handleCoinChange}
              />
              <FieldInfo
                info={serverSideError?.budget ? serverSideError?.budget[0] : undefined}
                className="text-danger mt-1"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

// const CoinRewardFields = Form.Create({name : 'coinRewardForm'})(CoinRewardComponent)

export default withRouter(CoinRewardComponent)
