import * as actions from './action'

const initialStore = {
    data : [],
    total : undefined,
    currentPage : undefined,
    response : {},
    category : undefined,
    business : undefined,
    surveyId : undefined
}

export default function ResponseReducer(state = initialStore, action){
    switch(action.type){
        case actions.SET_RESPONSE_DATA:
            return {
                ...state,
                data : action.payload
            }
        case actions.SET_RESPONSE_META:
            // console.log('at reducer', action.surveyId)
            return {
                ...state,
                total : action.total,
                currentPage : action.currentPage,
                surveyId : action.surveyId
            }
        case actions.SET_SINGLE_RESPONSE:
            return{
                ...state,
                response : action.payload
            }
        case actions.SET_RESPONSE_CATEGORY:
            // console.log(action.payload)
            return{
                ...state,
                category : action.payload
            }
        case actions.SET_RESPONSE_BUSINESS:
            return{
                ...state,
                business : action.payload
            }
        default: 
            return state
    }
}