export const locationCategory = [
  'Urban',
  'Suburban',
  'Second City',
  'Town',
  'Village',
  'Rural',
  'Extreme Rural'
]

export const family = [
  'Single',
  'Parents',
  'Spouse',
  'Kids',
  'Siblings',
  'Siblings\'s Family',
  'Relative (2-4)',
  'Parents in law'
]

export const dependents = [
  'Single',
  'Parents',
  'Spouse',
  'Kids',
  'Siblings',
  'Sibbling\'s Family',
  'Relative (2-4)',
  'Parents in law'
]

export const educationCategory = [
  'School',
  'High School',
  'Bachelors',
  'Masters',
  'Above Masters',
  'Vocational Diplomas'
]

export const roleRepresented = [
  'MD / CEO / Owner / Promoter',
  'Senior Management',
  'Mid Management',
  'Supervision',
  'Technical',
  'Programs / Support Assistant',
  'Worker',
  'Consultant / Specialists / Instructors',
  'Practitioner (Doctor, Lawyer, CA, CS)'
]

export const hobbies = [
  'Singing',
  'Writing',
  'Swimming',
  'Hiking',
  'Adventure',
  'Traveling',
  'Poetry',
  'Art/Painting',
  'Music',
  'Other'
]
