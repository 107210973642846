import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
// import { Helmet } from 'react-helmet'

// import styles from './style.module.scss';
import BasicDetailsForm from './basicDetailsForm'

const BasicDetails = () => {
  let userToken = localStorage.getItem('povToken')
  const history = useHistory()

  useEffect(() => {
    if (userToken) {
      console.log('here')
    } else {
      history.push(`/user/login`)
    }
  }, [])

  return (
    <div className="container container-basic-detail">
      {/* <Helmet title="Basic Details" /> */}
      <div className="title-section">
        <h1>Basic Information</h1>
        <p className="text-dark text-center px-5">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias aliquid at autem
          commodi corporis, distinctio dolore eveniet explicabo facere iste laborum nobis officiis,
          placeat praesentium quia, sit soluta vel!
        </p>
      </div>
      <div className="form form--basic-detail authentication-form-wrapper">
        <BasicDetailsForm />
      </div>
    </div>
  )
}

export default BasicDetails
