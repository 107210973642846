import React, { useState, useEffect, useCallback } from 'react'
import { getAllOffers } from 'services/offer'
import { Select, Spin } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import FieldInfo from 'components/FormElements/FieldInfo'
import debounce from 'helpers/debounce'

const OfferComponent = ({ initialValue, onChange, error }) => {
  const dispatch = useDispatch()
  const [selectedOffer, setSelectedOffer] = useState(initialValue)
  const [offersData, setOffersData] = useState([])
  const [loading, setLoading] = useState(false)

  const appliedBusiness = useSelector(state => state.user.business)

  const handleSearch = keyword => {
    const params = {
      keyword,
      business: appliedBusiness?.key,
    }
    dispatch(getAllOffers({ params, successCallback: data => setOffersData(data) }))
  }
  const delayedSearch = useCallback(debounce(handleSearch, 500), [])
  useEffect(() => {
    const isOfferEmpty = !offersData.length
    if (isOfferEmpty) {
      delayedSearch('')
    }
    return () => [delayedSearch.cancel, clearTimeout(debounce)]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayedSearch])

  const handleOfferChanged = offer => {
    setSelectedOffer(offer)
  }

  useEffect(() => {
    let description
    offersData.forEach(offer => {
      if (offer.id === selectedOffer?.key) {
        description = offer.display_message
      }
    })
    const selectedOfferWithDescription = {
      ...selectedOffer,
      description,
    }
    onChange(selectedOfferWithDescription)
  }, [offersData, onChange, selectedOffer])

  const { Option } = Select

  return (
    <div>
      <Select
        allowClear
        labelInValue
        onSearch={delayedSearch}
        showSearch
        onChange={handleOfferChanged}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        notFoundContent={loading ? <Spin size="small" /> : null}
        value={selectedOffer}
        style={{ width: '100%' }}
      >
        {(offersData || []).map(offer => {
          return (
            <Option key={offer.id} value={offer.id}>
              {offer.name}
            </Option>
          )
        })}
      </Select>
      <FieldInfo info={error?.offer_id} className="text-danger" />
    </div>
  )
}

export default OfferComponent
