export const SET_RESPONSE_DATA = 'SET_RESPONSE_DATA'
export const SET_RESPONSE_META = 'SET_RESPONSE_META'
export const SET_SINGLE_RESPONSE = 'SET_SINGLE_RESPONSE'
export const SET_RESPONSE_CATEGORY = 'SET_RESPONSE_CATEGORY'
export const SET_RESPONSE_BUSINESS = 'SET_RESPONSE_BUSINESS'

export function setResponseData(payload){
    return {
        type : SET_RESPONSE_DATA,
        payload
    }
}

export function setResponseMeta(payload){
    return {
        type : SET_RESPONSE_META,
        total : payload.total,
        currentPage : payload.currentPage,
        surveyId : payload.surveyId
    }
}

export function setSingleResponse(payload){
    return{
        type : SET_SINGLE_RESPONSE,
        payload
    }
}

export function setResponseCategory(payload){
    return{
        type : SET_RESPONSE_CATEGORY,
        payload
    }
}

export function setResponseBusiness(payload){
    return{
        type : SET_RESPONSE_BUSINESS,
        payload
    }
}