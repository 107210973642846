import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import store from 'store'
import { Scrollbars } from 'react-custom-scrollbars'
import _ from 'lodash'
import uuid from 'helpers/uuid'
import styles from './style.module.scss'

const { Sider } = Layout
const { SubMenu, Divider } = Menu

const mapStateToProps = ({ menu, settings, user }) => ({
  businessData: user.business,
  menuData: menu.menuLeftData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isSettingsOpen: settings.isSettingsOpen,
  isLightTheme: settings.isLightTheme,
  isMobileMenuOpen: settings.isMobileMenuOpen,
})

@withRouter
@connect(mapStateToProps)
class MenuLeft extends React.Component {
  state = {
    selectedKeys: store.get('app.menu.selectedKeys') || [],
    openedKeys: store.get('app.menu.openedKeys') || [],
  }

  componentWillMount() {
    this.setSelectedKeys(this.props)
  }

  componentWillReceiveProps(newProps) {
    if (newProps.isMenuCollapsed && !newProps.isMobileView) {
      this.setState({
        openedKeys: [],
      })
    }
    this.setSelectedKeys(newProps)
  }

  setSelectedKeys = props => {
    const { menuData } = this.props
    // console.log(menuData);
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = _.find(flattenItems(menuData, 'children'), [
      'url',
      `/${props.location.pathname.split('/')[1]}`,
    ])
    // console.log(props.location.pathname.split('/')[1]);
    this.setState({
      selectedKeys: selectedItem ? [selectedItem.key] : [],
    })
  }

  onCollapse = (value, type) => {
    const { dispatch, isMenuCollapsed } = this.props
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }

    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })

    this.setState({
      openedKeys: [],
    })
  }

  onOpenChange = openedKeys => {
    store.set('app.menu.openedKeys', openedKeys)
    this.setState({
      openedKeys,
    })
  }

  handleClick = e => {
    const { dispatch, isSettingsOpen } = this.props
    store.set('app.menu.selectedKeys', [e.key])
    // custom action on settings menu item
    if (e.key === 'settings') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSettingsOpen',
          value: !isSettingsOpen,
        },
      })
      return
    }
    this.setState({
      selectedKeys: [e.key],
      // openKeys: e.keyPath,
    })
  }

  addPageToUrl = () => {
    if (!document.location.search) {
      history.pushState(null, null, `${document.location.pathname}?page=1`)
    }
  }

  generateMenuItems = () => {
    const { menuData = [], dispatch } = this.props
    const generateItem = item => {
      const { key, title, url, icon, disabled, pro } = item
      if (item.divider) {
        // return <div style={{marginTop : '50%'}}><Divider key={Math.random()} /></div>
        return <div key={uuid()} className="mb-auto p-2"></div>
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled} onClick={() => item.clearSession(dispatch)}>
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && (
                  <span className={`material-icons ${styles.icon} icon-collapsed-hidden`}>
                    {icon}
                  </span>
                )}
                <span className={styles.title}>{title}</span>
              </a>
            ) : (
              <Link to={url}>
                {icon && (
                  <span className={`material-icons ${styles.icon} icon-collapsed-hidden`}>
                    {icon}
                  </span>
                )}
                <span className={`${styles.title} ml-5`}>{title}</span>
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={uuid()} disabled={disabled}>
          {icon && (
            <span className={`material-icons ${styles.icon} icon-collapsed-hidden`}>{icon}</span>
          )}
          <span className={styles.title}>{title}</span>
        </Menu.Item>
      )
    }

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={uuid()}>
              <span className={styles.title}>{menuItem.title}</span>
              {menuItem.icon && (
                <span className={`material-icons ${styles.icon}`}>{menuItem.icon}</span>
              )}
            </span>
          )
          return (
            <SubMenu title={subMenuTitle} key={uuid()}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuData.map(menuItem => {
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={uuid()}>
            <span className={styles.title}>{menuItem.title}</span>
            {menuItem.icon && (
              <span className={`material-icons ${styles.icon}`}>{menuItem.icon}</span>
            )}
          </span>
        )
        return (
          <SubMenu title={subMenuTitle} key={uuid()}>
            {generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  render() {
    const { selectedKeys, openedKeys } = this.state
    const { isMobileView, isMenuCollapsed, isLightTheme, businessData } = this.props
    const menuSettings = isMobileView
      ? {
        width: 256,
        collapsible: false,
        collapsed: false,
        onCollapse: this.onCollapse,
      }
      : {
        width: 256,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse: this.onCollapse,
        breakpoint: 'lg',
      }

    const menu = this.generateMenuItems()

    return (
      <Sider
        {...menuSettings}
        className={isLightTheme ? `${styles.menu} ${styles.light}` : styles.menu}
      >
        <div className={styles.logo}>
          <div className={styles.logoContainer}>
            <img
              src={`resources/images/logo-inverse${menuSettings.collapsed ? '-mobile' : ''}.png`}
              alt=""
              className="d-none"
            />
          </div>
        </div>
        <Scrollbars
          className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: '4px',
                borderRadius: 'inherit',
                backgroundColor: '#c5cdd2',
                left: '1px',
              }}
            />
          )}
          autoHide
        >
          <Menu
            theme={isLightTheme ? 'light' : 'dark'}
            onClick={this.handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={this.onOpenChange}
            mode="inline"
            className={styles.navigation}
          >
            <div className={`px-4 mb-4 mt-4 ${menuSettings.collapsed && 'd-none'}`}>
              <h5>{businessData.label}</h5>
              <span style={{ lineHeight: 1.2 }}>
                {businessData?.address
                  ?.split(',')
                  .map((w) => {
                    if (w[0]) {
                      return `${w[0]?.toUpperCase() + w.substr(1).toLowerCase()}, `
                    }
                    return ''
                  })
                }
              </span>
            </div>
            {menu}
            {/* <Menu.Item key="home">
                <Link to='/dashboard'>
                  <span className={`icmn icmn-home ${styles.icon} icon-collapsed-hidden`} />
                  <span className={styles.title}>Home</span>
                </Link>
              </Menu.Item>
              <div className="mb-auto p-2"></div>
              <Menu.Item key="admin">
                <Link to='/admin'>
                  <span className={`icmn icmn-user ${styles.icon} icon-collapsed-hidden`} />
                  <span className={styles.title}>Admin</span>
                </Link>
              </Menu.Item> */}
          </Menu>
        </Scrollbars>
      </Sider>
    )
  }
}

export default connect()(MenuLeft)
