import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import survey from './survey/reducer'
import ad from './ads/reducer'
import feedback from './feedback/reducer'
import business from './business/reducer'
import category from './category/reducer'
import response from './response/reducer'
import filter from './filter/reducer'
import offer from './offer/reducer'
import errors from './errors/reducer'
import activity from './activity/reducer'
import roles from './roles/reducer'
import followup from './followup/reducer'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    survey,
    ad,
    feedback,
    business,
    category,
    response,
    filter,
    offer,
    errors,
    activity,
    roles,
    followup,
  })
