import React from 'react'
import { Layout } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import styles from './style.module.scss'

@withRouter
class LoginLayout extends React.PureComponent {
  state = {
    backgroundNumber: 1,
    backgroundEnabled: false,
  }

  changeBackground = () => {
    const { backgroundNumber } = this.state
    this.setState({
      backgroundEnabled: true,
      backgroundNumber: backgroundNumber === 5 ? 1 : backgroundNumber + 1,
    })
  }

  toggleBackground = () => {
    const { backgroundEnabled } = this.state
    this.setState({
      backgroundEnabled: !backgroundEnabled,
    })
  }

  render() {
    const { children } = this.props
    const { backgroundNumber, backgroundEnabled } = this.state

    return (
      <Layout>
        <Layout.Content>
          <div
            className={
              backgroundEnabled
                ? `${styles.layout} ${styles.light} authentication-layout`
                : `${styles.layout} authentication-layout`
            }
            style={{
              backgroundImage: backgroundEnabled
                ? `url('resources/images/photos/${backgroundNumber}.jpeg')`
                : `none`,
            }}
          >
            <div
              className={`${styles.header} authentication-layout-header authentication-layout-header--login`}
            >
              <div className={`${styles.logo}`}>
                <Link to="/">
                  {!backgroundEnabled && (
                    <img
                      src="resources/images/logo.png"
                      alt="Clean UI React Admin Template"
                      className="d-none"
                    />
                  )}
                </Link>
              </div>
            </div>
            <div className={`${styles.content}`}>{children}</div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default LoginLayout
