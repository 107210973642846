import React from 'react';

const PleaseScan = () => {
  return (
    <div className="please-scan-container">
      Please scan the QR code from the camera to proceed.
    </div>
  )
}

export default PleaseScan;
