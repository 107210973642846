import React from 'react'

const FieldInfo = ({info, ...props}) => {
    return <p 
                className="pl-1" 
                style={{color : 'rgba(0, 0, 0, 0.45)'}}
                {...props}
            >
                {info}
            </p>
}

export default FieldInfo