export const SET_ROLE_META = 'SET_ROLE_META'
export const SET_ROLE_DATA = 'SET_ROLE_DATA'
export const DELETE_ROLE = 'DELETE_ROLE'
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const CREATE_ROLE = 'CREATE_ROLE'

export function setRoleData(payload) {
  return {
    type: SET_ROLE_DATA,
    payload,
  }
}
export function createRole(payload) {
  return {
    type: CREATE_ROLE,
    payload,
  }
}

export function updateRole(payload) {
  return {
    type: UPDATE_ROLE,
    payload,
  }
}

export function setRoleMeta(meta) {
  return {
    type: SET_ROLE_META,
    total: meta.total,
    currentPage: meta.currentPage,
    searchParams: meta.searchParams,
  }
}

export function deleteRoleAction(payload) {
  return {
    type: DELETE_ROLE,
    payload,
  }
}
