export const SET_AD_META = 'SET_AD_META'
export const SET_HAS_META = 'SET_HAS_META'
export const SET_AD_QUESTIONS = 'SET_AD_QUESTIONS'
export const SET_AD_DATA = 'SET_AD_DATA'
export const DELETE_AD = 'DELETE_AD'
export const UPDATE_AD = 'UPDATE_AD'
export const SET_AD_KEYWORD = 'SET_AD_KEYWORD'
export const SET_AD_BUSINESS = 'SET_AD_BUSINESS'
export const CLEAR_AD_SESSION = 'CLEAR_AD_SESSION'
export const SET_AD_DATE = 'SET_AD_DATE'
export const CREATE_A_AD = 'CREATE_A_AD'
export const SHOULD_ADD_QUESTION = 'SHOULD_ADD_QUESTION'
export const UPDATE_AD_STATUS = 'UPDATE_AD_STATUS'

export function setADData(payload) {
  return {
    type: SET_AD_DATA,
    payload,
  }
}

export function createAnAd(payload) {
  return {
    type: CREATE_A_AD,
    payload,
  }
}

export function updateAD(payload) {
  return {
    type: UPDATE_AD,
    payload,
  }
}

export function setADMeta(meta) {
  return {
    type: SET_AD_META,
    total: meta.total,
    currentPage: meta.currentPage,
    searchParams: meta.searchParams,
    isAllAd: meta?.isAllAd
  }
}

export function deleteADAction(id) {
  return {
    type: DELETE_AD,
    payload: id,
  }
}

export function setADKeyword(keyword) {
  return {
    type: SET_AD_KEYWORD,
    payload: keyword,
  }
}

export function setADBusiness(business) {
  return {
    type: SET_AD_BUSINESS,
    payload: business,
  }
}

export function setAdDate(date) {
  return {
    type: SET_AD_DATE,
    from_date: date.from,
    to_date: date.to,
  }
}

export function clearADSession() {
  return {
    type: CLEAR_AD_SESSION,
  }
}
export function shouldAddQuestion(value) {
  return {
    type: SHOULD_ADD_QUESTION,
    payload: value,
  }
}
export function updateAdsStatus(payload) {
  return {
    type: UPDATE_AD_STATUS,
    payload,
  }
}
