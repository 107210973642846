import React from 'react'
import { Modal, Button } from 'antd'
import { CloseCircleTwoTone } from '@ant-design/icons'
import styles from './style.module.scss'

const CustomModal = props => {
  const {
    isModalOpen,
    onModalCancel,
    icon,
    title,
    info,
    onOk,
    onCancel,
    okText,
    cancelText,
    children,
    header,
    disableFooter,
    width,
  } = props
  return (
    <div>
      <Modal
        title={header}
        className={styles.modal}
        width={width}
        centered
        visible={isModalOpen}
        footer={
          !disableFooter && [
            <Button key="submit" type="primary" onClick={onOk}>
              {okText || 'Ok'}
            </Button>,
            <Button key="back" type="primary" ghost onClick={onCancel}>
              {cancelText || 'Cancel'}
            </Button>,
          ]
        }
        onCancel={onModalCancel}
        closeIcon={<CloseCircleTwoTone style={{ fontSize: '30px' }} />}
      >
        <div className={styles.cards}>
          {icon && <img className={styles.icon} src={icon} alt="icon" />}
          {title && <h5 className={styles.title}>{title}</h5>}
          {info && <p className={styles.info}>{info}</p>}
          <div>{children}</div>
        </div>
      </Modal>
    </div>
  )
}

export default CustomModal
