import React, { useState } from 'react'
import { Form, Input, Button, Radio, Select } from 'antd'
import { withRouter, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import queryString from 'query-string'

import { updateBasicDetails } from '../../../../services/user';
import { hobbies, locationCategory, dependents, educationCategory, family, roleRepresented } from '../../../../constants/basicDetailsForm.const';

const { Option } = Select;

const BasicDetailsForm = ({ history, form }) => {
  const { getFieldDecorator, validateFieldsAndScroll } = form;
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch()
  const location = useLocation();
  let qs = queryString.parse(location?.search);

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll((err, value) => {
      if (err) {
        return
      }
      setSubmitting(true)
      dispatch(updateBasicDetails({
        body: value,
        finalCallback: () => {
          setSubmitting(false)
        },
        successCallback: (response) => {
          history.push(qs?.redirectTo)
        }
      }))
    })
  }

  return (
    <>
      <Form
        onSubmit={handleSubmit}
      >
        <Form.Item
          label='Age'
        >
          {form.getFieldDecorator('age', {
            rules: [{ required: true, message: 'Age is required, please enter your age!' }],
          })(
            <Input
              placeholder="Age"
            />,
          )}
        </Form.Item>
        <Form.Item
          label='Gender'
        >
          {form.getFieldDecorator('gender', {
            rules: [{ required: true, message: 'Gender is required, please select your gender!!' }],
          })(
            <Radio.Group
            //  onChange={this.onChange}
            //  value={this.state.value}
            >
              <Radio value="Male">
                Male
              </Radio>
              <Radio value="Female">
                Female
              </Radio>
              <Radio value="Others">
                Others
              </Radio>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item
          label="Hobbies"
        >
          {form.getFieldDecorator('hobbies', {
            rules: [{ required: true, message: 'Hobbies is required, please select your hobbies!!' }],
          })(
            <Select
              placeholder="Select options"
              mode='multiple'
            >
              {hobbies?.map((hobby) => {
                return (
                  <Option value={hobby}>{hobby}</Option>
                )
              })}
            </Select>,
          )}
        </Form.Item>

        <Form.Item
          label="Location Category"
        >
          {form.getFieldDecorator('location_category', {
            rules: [{ required: true, message: 'Location category is required, please select your category!!' }],
          })(
            <Select
              placeholder="Select an option"
            >
              {locationCategory?.map((location) => {
                return (
                  <Option value={location}>{location}</Option>
                )
              })}
            </Select>,
          )}
        </Form.Item>

        <Form.Item
          label="Education Category"
        >
          {form.getFieldDecorator('education_category', {
            rules: [{ required: true, message: 'Education category is required, please select your category!!' }],
          })(
            <Select
              placeholder="Select an option"
            >
              {educationCategory?.map((education) => {
                return (
                  <Option value={education}>{education}</Option>
                )
              })}
            </Select>,
          )}
        </Form.Item>

        <Form.Item
          label="Role"
        >
          {form.getFieldDecorator('work_role', {
            rules: [{ required: true, message: 'Role is required, please select your role!!' }],
          })(
            <Select
              placeholder="Select an option"
            >
              {roleRepresented?.map((role) => {
                return (
                  <Option value={role}>{role}</Option>
                )
              })}
            </Select>,
          )}
        </Form.Item>

        <Form.Item
          label="Family Now"
        >
          {form.getFieldDecorator('family', {
            rules: [{ required: true, message: 'Family is required, please select your family!!' }],
          })(
            <Select
              placeholder="Select options"
              mode='multiple'
            >
              {family?.map((fam) => {
                return (
                  <Option value={fam}>{fam}</Option>
                )
              })}
            </Select>,
          )}
        </Form.Item>

        <Form.Item
          label="Dependents Back Home"
        >
          {form.getFieldDecorator('dependents', {
            rules: [{ required: true, message: 'Dependents is required, please select your dependents!!' }],
          })(
            <Select
              placeholder="Select options"
              mode='multiple'
            >
              {dependents?.map((fam) => {
                return (
                  <Option value={fam}>{fam}</Option>
                )
              })}
            </Select>,
          )}
        </Form.Item>

        <div className="d-flex justify-content-between form-actions">
          <div>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitting}
              className="login-form-button"
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </>
  )
}

const BasicForm = Form.create()(BasicDetailsForm)
export default withRouter(BasicForm)


