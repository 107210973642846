import { getApiCall, genericApiCall } from 'helpers/apiCall'
import { baseUri, urlWithParams, offerUrl } from 'helpers/urls'
import { message } from 'antd'
import { getsearchParamsFromRequestBody } from 'utils/helperFunction'
import {
  setOfferData,
  setOfferMeta,
  deleteOfferAction,
  updateOffer,
  createAnOffer,
} from 'redux/offer/action'
import { setErrors } from 'redux/errors/action'

export function getAllOffers({ successCallback, finalCallback, params }) {
  return dispatch => {
    const url = urlWithParams(`${baseUri}/${offerUrl}`, params)
    getApiCall({ url, dispatch })
      .then(resp => {
        if (resp && resp.success) {
          if (typeof successCallback === 'function') {
            successCallback(resp.data.data)
          }
          const searchParams = getsearchParamsFromRequestBody({
            page: resp.data.current_page.toString(),
            params,
          })
          dispatch(setOfferData(resp.data.data))
          dispatch(
            setOfferMeta({
              total: resp.data.total,
              currentPage: resp.data.current_page,
              searchParams,
            }),
          )
          return
        }
        message.error('Something went wrong!')
      })
      .catch(err => console.warn(err))
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function getSingleOffer({ id, callback }) {
  return dispatch => {
    const url = `${baseUri}/${offerUrl}/${id}`
    getApiCall({ url, dispatch })
      .then(resp => {
        if (resp?.success) {
          if (typeof callback === 'function') {
            callback(resp.data.data)
          }
          return
        }
        message.error('Something went wrong!')
      })
      .catch(err => console.warn(err))
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function deleteOffer({ id, finalCallback }) {
  return dispatch => {
    const url = `${baseUri}/${offerUrl}/${id}`
    genericApiCall({ id, dispatch, url, method: 'DELETE' })
      .then(resp => {
        if (resp?.data.success) {
          message.success('Successfully deleted offer')
          dispatch(deleteOfferAction(id))
        } else {
          message.error(`Sorry. couldn't delete this offer`)
        }
      })
      .catch(err => console.warn(err))
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function newOffer({ body, finalCallback, successCallback }) {
  return dispatch => {
    const url = `${baseUri}/${offerUrl}`
    genericApiCall({ dispatch, url, method: 'POST', body })
      .then(resp => {
        if (resp?.data.success) {
          if (typeof successCallback === 'function') {
            successCallback()
          }
          dispatch(createAnOffer(resp.data.data.data))
          message.success(resp.data.message)
          return
        }
        message.error('Something went wrong!')
      })
      .catch(err => {
        console.warn(err)
        if (!err?.response?.data?.success) {
          dispatch(setErrors(err?.response?.data?.data?.errors))
          return
        }
        message.error('Something went wrong!')
      })
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function editOffer({ id, body, finalCallback, successCallback }) {
  return dispatch => {
    const url = `${baseUri}/${offerUrl}/${id}`
    genericApiCall({ id, dispatch, url, method: 'POST', body })
      .then(resp => {
        if (resp?.data?.success) {
          if (typeof successCallback === 'function') {
            successCallback()
          }
          dispatch(updateOffer(resp.data.data.data))
          message.success(resp.data.message)
          return
        }
        message.error('Something went wrong!')
      })
      .catch(err => {
        console.warn(err)
        if (!err?.response?.data?.success) {
          dispatch(setErrors(err?.response?.data?.data?.errors))
          return
        }
        message.error('Something went wrong!')
      })
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}
