import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

const DetailTitle = ({ history, match }) => {
  const [id] = useState(match.params.feedback_id || match.params.survey_id || match.params.ad_id)
  const [page] = useState(history.location.pathname.split('/')[1])

  const navigateToEdit = () => {
    history.push(`/${page}/edit/${id}`)
  }
  return (
    <div className="d-flex justify-content-between border-bottom mb-2">
      <h4>Detail Information</h4>
      {/* <div role="presentation" style={{cursor : 'pointer'}} className="d-flex align-items-center" onClick={navigateToEdit}>
            <span className="material-icons" style={{fontSize : '14px'}}>edit</span>
            <span className="mx-2">Edit</span>
        </div> */}
    </div>
  )
}

export default withRouter(DetailTitle)
