import React from 'react'

const ShowDescription = ({ title, description, range }) => {
  return (
    <>
      <h3 className="font-weight-bold">{title}</h3>
      {range && <p className="font-weight-bold">{range}</p>}
      <p>{description}</p>
    </>
  )
}
export default ShowDescription
