import Axios from 'axios'
import {
  baseUri,
  surveyUrl,
  surveyStatusPatchUrl,
  urlWithParams,
  questionUrl,
  businessUrl,
  defaultFeedbackUrl,
} from 'helpers/urls'
import { getApiCall, genericApiCall } from 'helpers/apiCall'
import { createFormFromApi, getsearchParamsFromRequestBody } from 'utils/helperFunction'
import {
  setFeedbackData,
  setFeedbackMeta,
  deleteFeedbackAction,
  updateFeedback,
  createAFeedback,
  updateStatus,
} from 'redux/feedback/action'
import { message } from 'antd'
import { setErrors } from 'redux/errors/action'

export function getAllFeedback({ params, finalCallback }) {
  return dispatch => {
    dispatch(setFeedbackData([]))
    const url = urlWithParams(`${baseUri}/${surveyUrl}`, params)
    getApiCall({ url, dispatch })
      .then(resp => {
        if (resp?.success) {
          const searchParams = getsearchParamsFromRequestBody({
            page: resp?.data?.current_page.toString(),
            params,
          })
          dispatch(setFeedbackData(resp?.data?.data))
          dispatch(
            setFeedbackMeta({
              total: resp?.data?.total,
              currentPage: resp?.data?.current_page,
              isAllFeedback: params?.status === undefined ? true : false,
              searchParams,
            }),
          )
          return
        }
        message.error(`Sorry couldn't fetch all feedbacks. Something went wrong!`)
      })
      .catch(err => console.warn(err))
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function getFeedback({ id, callback }) {
  return dispatch => {
    const url = `${baseUri}/${surveyUrl}/${id}`
    getApiCall({ url, dispatch })
      .then(resp => {
        if (resp?.success) {
          callback(resp?.data?.data)
          return
        }
        message.error('Sorry something went wrong!')
      })
      .catch(err => console.warn(err))
  }
}

export function createFeedback({ body, successCallback, finalCallback }) {
  return dispatch => {
    const url = `${baseUri}/${surveyUrl}`
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('povToken')}`,
      },
    }
    Axios.post(url, body, config)
      .then(resp => {
        if (resp?.data?.success) {
          message.success(resp?.data?.message)
          dispatch(createAFeedback(resp?.data?.data?.data))
          successCallback(resp?.data?.data?.data?.id, resp?.data?.data?.data?.status)
        } else {
          message.error(resp?.data?.message)
        }
      })
      .catch(err => {
        if (!err?.response?.data?.success) {
          dispatch(setErrors(err?.response?.data?.data?.errors))
        }
      })
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function editFeedback({ body, successCallback, id, finalCallback }) {
  return dispatch => {
    const url = `${baseUri}/${surveyUrl}/${id}`
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('povToken')}`,
      },
    }
    Axios.put(url, body, config)
      .then(resp => {
        if (resp?.data?.success) {
          dispatch(updateFeedback(resp?.data?.data?.data))
          successCallback(resp?.data?.data?.data?.id, resp.data?.data?.data?.status)
          message.success(resp?.data?.message)
        } else {
          message.error(resp?.data?.message)
        }
      })
      .catch(err => {
        if (!err?.response?.data?.success) {
          dispatch(setErrors(err?.response?.data?.data?.errors))
        }
      })
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function deleteFeedback({ id }) {
  return dispatch => {
    const url = `${baseUri}/${surveyUrl}/${id}`
    genericApiCall({ url, method: 'DELETE' })
      .then(resp => {
        if (resp?.data?.success) {
          dispatch(deleteFeedbackAction(id))
          message.success(resp?.data?.message)
        } else {
          message.error(resp?.data?.message)
        }
      })
      .catch(err => console.warn(err))
  }
}

export function getAllQuestion({ callback }) {
  return dispatch => {
    const url = `${baseUri}/${questionUrl}`
    getApiCall({ dispatch, url })
      .then(resp => {
        const questions = createFormFromApi(resp?.data?.data)
        callback(questions)
      })
      .catch(err => console.warn(err))
  }
}

export function setDefaultFeedback({ body }) {
  return dispatch => {
    const url = `${baseUri}/${businessUrl}/${defaultFeedbackUrl}`
    genericApiCall({ url, method: 'POST', body })
      .then(resp => {
        if (resp?.data?.success) {
          message.success(resp?.data?.message)
        }
        // console.warn(resp)
      })
      .catch(err => console.warn(err))
  }
}

// export function changeStatus({ id, body, callback }) {
//   return dispatch => {
//     const url = `${baseUri}/${surveyUrl}/${surveyStatusPatchUrl}/${id}`
//     genericApiCall({ body, url, method: 'PATCH' })
//       .then(resp => {
//         if (resp?.data?.success) {
//           dispatch(updateStatus(resp?.data?.data?.data))
//           message.success('Status Changed Successfully.')
//           return
//         }
//         message.error('Something went wrong !')
//       })
//       .catch(err => console.warn(err))
//   }
// }
