import Axios from 'axios'
import {
  baseUri,
  surveyUrl,
  statusPatchUrl,
  urlWithParams,
  questionUrl,
  bulkCreateUrl,
  bulkUpdateQuestionsUrl,
  qrCodeUrl,
} from 'helpers/urls'
import { getApiCall, genericApiCall } from 'helpers/apiCall'
import { createFormFromApi, getsearchParamsFromRequestBody } from 'utils/helperFunction'
import {
  setSurveyData,
  setSurveyMeta,
  deleteSurveyAction,
  createASurvey,
  updateSurvey,
  updateSurveyStatus,
} from 'redux/survey/action'
import { deleteFeedbackAction, updateFeedbackStatus } from 'redux/feedback/action'
import { message } from 'antd'
import { setErrors } from 'redux/errors/action'

export function getAllSurvey({ params, finalCallback }) {
  return dispatch => {
    const url = urlWithParams(`${baseUri}/${surveyUrl}`, params)
    getApiCall({ url, dispatch })
      .then(resp => {
        if (resp && resp.success) {
          const searchParams = getsearchParamsFromRequestBody({
            page: resp?.data?.current_page.toString(),
            params,
          })
          dispatch(setSurveyData(resp.data.data))
          console.log('survey meta here', params?.status === undefined ? true : false)
          dispatch(
            setSurveyMeta({
              total: resp?.data?.total,
              currentPage: resp?.data?.current_page,
              isAllSurvey: params?.status === undefined ? true : false,
              searchParams,
            }),
          )
          return
        }
        message.error('Something went wrong!')
      })
      .catch(err => console.warn(err))
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function getSurvey({ id, callback, count }) {
  return dispatch => {
    let url = `${baseUri}/${surveyUrl}/${id}`
    if (count) {
      url = `${url}?viewcount`
    }
    getApiCall({ url, dispatch })
      .then(resp => {
        if (resp && resp.success) {
          callback(resp?.data?.data)
          return
        }
        message.error('Something went wrong !')
      })
      .catch(err => console.warn(err))
  }
}

export function createSurveyMeta({ body, successCallback, finalCallback }) {
  return dispatch => {
    const url = `${baseUri}/${surveyUrl}`
    console.warn('at call', body)
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('povToken')}`,
      },
    }
    Axios.post(url, body, config)
      .then(resp => {
        if (resp.data.success) {
          dispatch(createASurvey(resp?.data?.data?.data))
          message.success(resp?.data?.message)
          successCallback(resp?.data?.data?.data?.id, resp?.data?.data?.data?.status)
        } else {
          message.error(resp?.data?.message)
        }
      })
      .catch(err => {
        if (!err?.response?.data?.success) {
          dispatch(setErrors(err?.response?.data?.data?.errors))
        }
      })
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function createSurveyForm({ body, successCallback, id, finalCallback }) {
  return dispatch => {
    const url = `${baseUri}/${questionUrl}/${bulkCreateUrl}`
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('povToken')}`,
      },
    }
    Axios.post(url, body, config)
      .then(resp => {
        if (resp?.data?.success) {
          successCallback()
          message.success(resp?.data?.message)
        } else {
          message.error(resp?.data?.message)
        }
      })
      .catch(err => console.warn(err))
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function editSurveyForm({ body, successCallback }) {
  return dispatch => {
    const url = `${baseUri}/${questionUrl}/${bulkUpdateQuestionsUrl}`
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('povToken')}`,
      },
    }
    Axios.put(url, body, config)
      .then(resp => {
        if (resp?.data?.success) {
          successCallback()
          message.success(resp?.data?.message)
        } else {
          message.error(resp?.data?.message)
        }
      })
      .catch(err => console.warn(err))
  }
}

export function editSurveyMeta({ body, successCallback, id, finalCallback }) {
  return dispatch => {
    const url = `${baseUri}/${surveyUrl}/${id}`
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('povToken')}`,
      },
    }
    Axios.post(url, body, config)
      .then(resp => {
        if (resp?.data?.success) {
          dispatch(updateSurvey(resp?.data?.data?.data))
          successCallback(resp?.data?.data?.data?.id, resp.data?.data?.data?.status)
          message.success(resp?.data?.message)
        } else {
          message.error(resp?.data?.message)
        }
      })
      .catch(err => {
        if (!err?.response?.data?.success) {
          dispatch(setErrors(err?.response?.data?.data?.errors))
        }
      })
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function deleteSurvey({ id, type }) {
  return dispatch => {
    const url = `${baseUri}/${surveyUrl}/${id}`
    genericApiCall({ url, method: 'DELETE' })
      .then(resp => {
        if (resp?.data?.success) {
          if (type === 'feedback') {
            dispatch(deleteFeedbackAction(id))
          } else if (type === 'survey') {
            dispatch(deleteSurveyAction(id))
          }
          message.success(resp?.data?.message)
        } else {
          message.error(resp?.data?.message)
        }
      })
      .catch(err => console.warn(err))
  }
}

export function getAllQuestion({ callback }) {
  return dispatch => {
    const url = `${baseUri}/${questionUrl}`
    getApiCall({ dispatch, url })
      .then(resp => {
        const questions = createFormFromApi(resp?.data?.data)
        callback(questions)
      })
      .catch(err => console.warn(err))
  }
}

export function downloadQrCode({ id, finalCallback }) {
  return () => {
    const url = `${baseUri}/${surveyUrl}/${qrCodeUrl}/${id}`
    Axios({
      responseType: 'blob',
      url,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('povToken')}`,
      },
    })
      .then(response => {
        const url = URL.createObjectURL(response?.data)
        const link = document.createElement('a')
        link.href = url
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch(err => {
        console.log('something went wrong', err)
        message.error('Something went wrong with the download.')
      })
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}

export function changeStatus({ id, body, type, finalCallback }) {
  return dispatch => {
    const url = `${baseUri}/${surveyUrl}/${statusPatchUrl}/${id}`
    genericApiCall({ body, url, method: 'PATCH' })
      .then(resp => {
        if (resp?.data?.success) {
          if (type === 'feedback') {
            dispatch(updateFeedbackStatus(resp?.data?.data?.data))
          } else if (type === 'survey') {
            dispatch(updateSurveyStatus(resp?.data?.data?.data))
          }

          message.success('Status Changed Successfully.')
          return
        }
        message.error('Something went wrong !')
      })
      .catch(err => console.warn(err))
      .finally(() => {
        if (typeof finalCallback === 'function') {
          finalCallback()
        }
      })
  }
}
