export const SET_BUSINESS_META = 'SET_BUSINESS_META'
export const SET_BRANCHES_META = 'SET_BRANCHES_META'
export const SET_BUSINESS_QUESTIONS = 'SET_BUSINESS_QUESTIONS'
export const SET_BUSINESS_DATA = 'SET_BUSINESS_DATA'
export const SET_BRANCHES_DATA = 'SET_BRANCHES_DATA'
export const DELETE_BUSINESS = 'DELETE_BUSINESS'
export const UPDATE_BUSINESS = 'UPDATE_BUSINESS'
export const UPDATE_BRANCH = 'UPDATE_BRANCH'
export const NEW_BUSINESS = 'NEW_BUSINESS'
export const CLEAR_BUSINESS_SESSION = 'CLEAR_BUSINESS_SESSION'
export const SET_BUSINESS_KEYWORD = 'SET_BUSINESS_KEYWORD'
export const SET_APPLIED_BUSINESS_DATA = 'SET_APPLIED_BUSINESS_DATA'

export function setBusinessData(payload) {
  return {
    type: SET_BUSINESS_DATA,
    payload,
  }
}
export function setBranchesData(payload) {
  return {
    type: SET_BRANCHES_DATA,
    payload,
  }
}

export function setAppliedBusinessData(payload) {
  return {
    type: SET_APPLIED_BUSINESS_DATA,
    payload,
  }
}

export function updateBusiness(payload) {
  return {
    type: UPDATE_BUSINESS,
    payload,
  }
}
export function updateBranch(payload) {
  return {
    type: UPDATE_BRANCH,
    payload,
  }
}

export function createNewBusiness(payload) {
  return {
    type: NEW_BUSINESS,
    payload,
  }
}

export function setBusinessKeyword(payload) {
  return {
    type: SET_BUSINESS_KEYWORD,
    payload,
  }
}

export function setBusinessMeta(payload) {
  return {
    type: SET_BUSINESS_META,
    total: payload.total,
    currentPage: payload.currentPage,
    searchParams: payload.searchParams,
  }
}
export function setBranchesMeta(payload) {
  return {
    type: SET_BRANCHES_META,
    // total: payload.total,
    // currentPage: payload.currentPage,
    searchParams: payload.searchParams,
  }
}

export function deleteBusinessAction(id) {
  return {
    type: DELETE_BUSINESS,
    payload: id,
  }
}

export function clearBusinessSession() {
  return {
    type: CLEAR_BUSINESS_SESSION,
  }
}
