export const SET_BUSINESS_FILTER_DATA = 'SET_BUSINESS_FILTER_DATA'
export const SET_CATEGORY_FILTER_DATA = 'SET_CATEGORY_FILTER_DATA'
export const SET_SURVEY_KEYWORD_FILTER_DATA = 'SET_SURVEY_KEYWORD_FILTER_DATA'
export const SET_FEEDBACK_KEYWORD_FILTER_DATA = 'SET_FEEDBACK_KEYWORD_FILTER_DATA'
export const SET_AD_KEYWORD_FILTER_DATA = 'SET_AD_KEYWORD_FILTER_DATA'


export function setBusinessFilterData(payload){
    return {
        type : SET_BUSINESS_FILTER_DATA,
        payload
    }
}

export function setCategoryFilterData(payload){
    return {
        type : SET_CATEGORY_FILTER_DATA,
        payload
    }
}

export function setSurveyFilterData(payload){
    return {
        type : SET_SURVEY_KEYWORD_FILTER_DATA,
        payload
    }
}

export function setFeedbackFilterData(payload){
    return {
        type : SET_FEEDBACK_KEYWORD_FILTER_DATA,
        payload
    }
}

export function setAdFilterData(payload){
    return {
        type : SET_AD_KEYWORD_FILTER_DATA,
        payload
    }
}