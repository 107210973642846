import { updateArr } from 'utils/helperFunction'
import * as actions from './action'

const initialStore = {
  data: [],
}

export default function followUpReducer(state = initialStore, action) {
  switch (action.type) {
    case actions.GET_FOLLOWUP:
      return {
        ...state,
        data: action.payload,
      }
    case actions.CREATE_FOLLOWUP:
      const newData = [action.payload, ...state.data]
      return {
        ...state,
        data: newData,
      }

    default:
      return state
  }
}
