import * as actions from './action'

const initialStore = {
    businessData : [],
    categoryData : [],
    survey : [],
    feedback : [],
    ad: []
}

export default function FilterReducer(state = initialStore, action){
    switch(action.type){
        case actions.SET_BUSINESS_FILTER_DATA:
            return{
                ...state,
                businessData : action.payload
            }
        case actions.SET_CATEGORY_FILTER_DATA:
            return{
                ...state,
                categoryData : action.payload
            }
        case actions.SET_SURVEY_KEYWORD_FILTER_DATA:
            return{
                ...state,
                survey : action.payload
            }
        case actions.SET_FEEDBACK_KEYWORD_FILTER_DATA:
            return{
                ...state,
                feedback : action.payload
            }
        case actions.SET_AD_KEYWORD_FILTER_DATA:
            return{
                ...state,
                ad : action.payload
            }
        default: 
            return state
    }
}