export const SET_CATEGORY_META = 'SET_CATEGORY_META'
export const SET_CATEGORY_QUESTIONS = 'SET_CATEGORY_QUESTIONS'
export const SET_CATEGORY_DATA = 'SET_CATEGORY_DATA'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const NEW_CATEGORY = 'NEW_CATEGORY'
export const CLEAR_CATEGORY_SESSION = 'CLEAR_CATEGORY_SESSION'
export const SET_CATEGORY_KEYWORD = 'SET_CATEGORY_KEYWORD'

export function setCategoryData(payload){
    return {
        type : SET_CATEGORY_DATA,
        payload
    }
}

export function updateCategory(payload){
    return{
        type : UPDATE_CATEGORY,
        payload
    }
}

export function createNewCategory(payload){
    return{
        type : NEW_CATEGORY,
        payload
    }
}

export function setCategoryMeta(meta){
    return {
        type : SET_CATEGORY_META,
        payload : meta.total,
        currentPage : meta.currentPage,
        searchParams : meta.searchParams
    }
}

export function deleteCategoryAction(id){
    return{
        type : DELETE_CATEGORY,
        payload : id
    }
}

export function setCategoryKeyword(keyword){
    return{
        type : SET_CATEGORY_KEYWORD,
        payload : keyword
    }
}

export function clearCategorySession(){
    return{
        type : CLEAR_CATEGORY_SESSION
    }
}