import {updateArr} from 'utils/helperFunction'
import * as actions from './action'

const initialStore = {
    data : [],
    currentPage : 1,
    total : undefined,
    searchParams : {}
}

export default function activityReducer(state = initialStore, action){
    switch(action.type){
        case actions.SET_ACTIVITY_META:
            return {
                ...state,
                total : action.total || state.data?.length,
                currentPage : action.currentPage,
                searchParams : action.searchParams
            }
            case actions.SET_ACTIVITY_DATA:
                return {
                    ...state,
                    data : action.payload
                }
            case actions.DELETE_ACTIVITY:
                const withoutDeleted = state.data.filter(activity => activity.id !== action.payload)
                return {
                    ...state,
                    data : withoutDeleted
                }
            case actions.CREATE_AN_ACTIVITY:
                const newData = [action.payload, ...state.data]
                return {
                    ...state,
                    data : newData
                }
            case actions.UPDATE_ACTIVITY:
                const updated_activity = updateArr(state.data, action.payload)
                return {
                    ...state,
                    data : updated_activity
                }
            default :
                return state
    }
}
