import React from 'react'

const changedArray = (a = [], b = []) =>
  a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]))

const initialState = { error: null, info: null }
class ErrorBoundary extends React.Component {
  state = initialState

  componentDidUpdate(prevProps) {
    const { error } = this.state
    const { resetKeys, onResetKeysChange } = this.props
    if (error !== null && changedArray(prevProps.resetKeys, resetKeys)) {
      // eslint-disable-next-line no-unused-expressions
      onResetKeysChange?.(prevProps.resetKeys, resetKeys)
      this.setState(initialState)
    }
  }

  resetErrorBoundary = (...args) => {
    const { onReset } = this.props
    // eslint-disable-next-line no-unused-expressions
    onReset?.(...args)
    this.setState(initialState)
  }

  componentDidCatch(error, info) {
    const { onError } = this.props
    // eslint-disable-next-line no-unused-expressions
    onError?.(error, info?.componentStack)
    this.setState({ error, info })
  }

  render() {
    const { error, info } = this.state
    const { fallbackRender, FallbackComponent, fallback, children } = this.props

    if (error !== null) {
      const props = {
        componentStack: info?.componentStack,
        error,
        resetErrorBoundary: this.resetErrorBoundary,
      }
      if (React.isValidElement(fallback)) {
        return fallback
      }
      if (typeof fallbackRender === 'function') {
        return fallbackRender(props)
      }
      if (typeof FallbackComponent === 'function') {
        return <FallbackComponent {...props} />
      }
      throw new Error(
        'react-error-boundary requires either a fallback, fallbackRender, or FallbackComponent prop',
      )
    }

    return children
  }
}

function withErrorBoundary(Component, errorBoundaryProps) {
  function Wrapped(props) {
    return (
      <ErrorBoundary {...errorBoundaryProps}>
        <Component {...props} />
      </ErrorBoundary>
    )
  }

  // Format for display in DevTools
  const name = Component.displayName || Component.name || 'Unknown'
  Wrapped.displayName = `withErrorBoundary(${name})`

  return Wrapped
}

export { ErrorBoundary, withErrorBoundary }
