import axios from 'axios'
import { message } from 'antd'
import { baseUri } from './urls';

let authToken = localStorage.getItem('povToken')

export const setToken = token => {
  authToken = token;
}

function getEncodedParams(body) {
  const params = new URLSearchParams()
  Object.keys(body).forEach(key => {
    if (body[key]) {
      params.append(key, body[key])
    }
  })
  return params
}

export const getApiCall = ({ id, dispatch, url }) => {
  // console.log(headers);
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('povToken')}`
  }
  return axios({
    url,
    method: 'GET',
    headers,
  })
    .then(response => {
      dispatch({ type: 'null', payload: '' })
      // console.log(id)
      if (response.status.toString()[0] !== '2') {
        // console.log(response.data);
        message.error('Something went wrong');
        return 0
      }
      return response.data
    })
    .catch(err => {
      console.log(err);
    })
}

export const genericApiCall = ({ id, dispatch, url, method, body }) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('povToken')}`,
    // 'Content-Type' : 'application/x-www-form-urlencoded'
  }
  return axios({
    url,
    method,
    headers,
    data: body
  })
}


export const postApiCall = ({ url, data, dispatch }) => {
  let finalUrl = `${baseUri}/${url}`;
  return fetch(finalUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('povToken')}`,
      Accept: 'application/json',
      // 'Content-Type': 'multipart/form-data',
    },
    body: data,
  }).then((response) => {
    if (response.status === 500) {
      message.error('Oops! somethind went wrong. (errorCode:ERR_500)')
    } else {
      return response.json();
    }
  });
};
