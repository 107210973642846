import React from 'react'
import { BackTop, Layout, Button } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import TopBar from 'components/LayoutComponents/TopBar'
import Menu from 'components/LayoutComponents/Menu'
import Breadcrumb from 'components/LayoutComponents/Breadcrumbs/BreadCrumb'
import Settings from 'components/LayoutComponents/Settings'
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary'

const mapStateToProps = ({ settings }) => ({
  isBorderless: settings.isBorderless,
  isSquaredBorders: settings.isSquaredBorders,
  isFixedWidth: settings.isFixedWidth,
  isMenuShadow: settings.isMenuShadow,
  isMenuTop: settings.isMenuTop,
})
@withRouter
@connect(mapStateToProps)
class MainLayout extends React.PureComponent {
  render() {
    const {
      children,
      isBorderless,
      isSquaredBorders,
      isFixedWidth,
      isMenuShadow,
      isMenuTop,
    } = this.props
    return (
      <Layout
        className={classNames({
          settings__borderLess: isBorderless,
          settings__squaredBorders: isSquaredBorders,
          settings__fixedWidth: isFixedWidth,
          settings__menuShadow: isMenuShadow,
          settings__menuTop: isMenuTop,
        })}
      >
        <BackTop />
        <Menu />
        <Settings />
        <Layout>
          <Layout.Header>
            <TopBar />
          </Layout.Header>
          <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div className="card my-5">
                <div className="card-header">
                  <div role="alert" className="mb-2">
                    <div>Something Went wrong!!</div>
                    <pre>{error.message}</pre>
                    <Button
                      onClick={() => {
                        resetErrorBoundary()
                      }}
                    >
                      Try again
                    </Button>
                  </div>
                </div>
              </div>
            )}
          >
            <Layout.Content style={{ height: '100%', position: 'relative' }}>
              <Breadcrumb />
              <div className="utils__content">{children}</div>
            </Layout.Content>
          </ErrorBoundary>
        </Layout>
      </Layout>
    )
  }
}

export default MainLayout
