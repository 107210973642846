import React from 'react';
import { Slider, Icon } from 'antd';

class IconSlider extends React.Component {

  state = {
    value: 0,
  };

  handleChange = value => {
    const { onChange } = this.props;
    this.setState({ value });
    onChange(value)
  };

  render() {
    const { max, min, minLabel, maxLabel } = this.props;
    const { value } = this.state;
    const mid = ((max - min) / 2).toFixed(5);
    const preColor = value >= mid ? '' : 'rgba(0, 0, 0, .45)';
    const nextColor = value >= mid ? 'rgba(0, 0, 0, .45)' : '';
    return (
      <div className="range-icon-wrapper">
        {/* <Icon style={{ color: preColor }} type="frown-o" /> */}
        <span>{minLabel}</span>
        <Slider {...this.props} onChange={this.handleChange} value={value} />
        {/* <Icon style={{ color: nextColor }} type="smile-o" /> */}
        <span>{maxLabel}</span>
      </div>
    );
  }
}

export default IconSlider;
