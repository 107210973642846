export const SET_SURVEY_META = 'SET_SURVEY_META'
export const SET_HAS_META = 'SET_HAS_META'
export const SET_SURVEY_QUESTIONS = 'SET_SURVEY_QUESTIONS'
export const SET_SURVEY_DATA = 'SET_SURVEY_DATA'
export const DELETE_SURVEY = 'DELETE_SURVEY'
export const UPDATE_SURVEY = 'UPDATE_SURVEY'
export const SET_SURVEY_KEYWORD = 'SET_SURVEY_KEYWORD'
export const SET_SURVEY_BUSINESS = 'SET_SURVEY_BUSINESS'
export const CLEAR_SURVEY_SESSION = 'CLEAR_SURVEY_SESSION'
export const SET_SURVEY_DATE = 'SET_SURVEY_DATE'
export const CREATE_A_SURVEY = 'CREATE_A_SURVEY'
export const SHOULD_ADD_QUESTION = 'SHOULD_ADD_QUESTION'
export const UPDATE_STATUS = 'UPDATE_STATUS'

export function setSurveyData(payload) {
  return {
    type: SET_SURVEY_DATA,
    payload,
  }
}

export function createASurvey(payload) {
  return {
    type: CREATE_A_SURVEY,
    payload,
  }
}

export function updateSurvey(payload) {
  return {
    type: UPDATE_SURVEY,
    payload,
  }
}

export function setSurveyMeta(meta) {
  return {
    type: SET_SURVEY_META,
    total: meta.total,
    currentPage: meta.currentPage,
    searchParams: meta.searchParams,
    isAllSurvey: meta?.isAllSurvey
  }
}

export function deleteSurveyAction(id) {
  return {
    type: DELETE_SURVEY,
    payload: id,
  }
}

export function setSurveyKeyword(keyword) {
  // console.log(keyword)
  return {
    type: SET_SURVEY_KEYWORD,
    payload: keyword,
  }
}

export function setSurveyBusiness(business) {
  return {
    type: SET_SURVEY_BUSINESS,
    payload: business,
  }
}

export function clearSurveySession() {
  return {
    type: CLEAR_SURVEY_SESSION,
  }
}

export function setSurveyDate(date) {
  return {
    type: SET_SURVEY_DATE,
    from_date: date.from,
    to_date: date.to,
  }
}
export function shouldAddQuestion(value) {
  return {
    type: SHOULD_ADD_QUESTION,
    payload: value,
  }
}
export function updateSurveyStatus(payload) {
  return {
    type: UPDATE_STATUS,
    payload,
  }
}
