import {updateArr} from 'utils/helperFunction'
import * as actions from './action'

const initialStore = {
    data : [],
    total : undefined,
    currentpage : 1,
    searchParms : {},
    keyword : undefined
}

export default function CategoryReducer(state=initialStore, action){
    switch(action.type){
        case actions.SET_CATEGORY_DATA:
            console.log(action.payload)
            return{
                ...state,
                data : action.payload
            }
        case actions.SET_CATEGORY_META:
            return{
                ...state,
                total : action.total || state.total,
                currentpage : action.currentpage,
                searchParams : action.searchParams
            }
        case actions.DELETE_CATEGORY:
            const withoutDeleted = state.data.filter(category => category.id !== action.payload)
            return {
                ...state,
                data : withoutDeleted
            }
        case actions.NEW_CATEGORY:
            const withNewCategory = [action.payload, ...state.data]
            return {
                data : withNewCategory
            }
        case actions.UPDATE_CATEGORY:
            const updated_category = updateArr(state.data, action.payload)
            return{
                ...state,
                data : updated_category
            }
        case actions.CLEAR_CATEGORY_SESSION:
            return {
                ...state,
                business : undefined,
                keyword : undefined,
                currentPage : 1
            }
        case actions.SET_CATEGORY_KEYWORD:
            return {
                ...state,
                keyword : action.payload
            }
        default :
            return state
    }
}