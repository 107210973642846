import React, { useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Switch } from 'antd'
import { changeStatus } from 'services/survey'
import { changeAdStatus } from 'services/ad'

const DetailHeader = ({ business, isActive, type }) => {
  const history = useHistory()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const [page] = useState(history.location.pathname.split('/')[1])
  const [id] = useState(match.params.feedback_id || match.params.survey_id || match.params.ad_id)
  const [active, setActive] = useState(false)

  const navigateToEdit = () => {
    history.push(`/${page}/edit/${id}`)
  }
  const handleActiveChange = value => {
    setActive(value)
    const payload = {
      status: value ? 'active' : 'inactive',
    }
    if (page === 'ad-management') {
      dispatch(
        changeAdStatus({
          body: payload,
          id,
        }),
      )
    } else {
      dispatch(
        changeStatus({
          body: payload,
          id,
          type,
        }),
      )
    }
  }

  useEffect(() => {
    if (isActive) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [isActive])

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <h3 className="font-weight-bold">{business}</h3>
          {/* <p
            onClick={navigateToEdit}
            role="presentation"
            style={{ cursor: 'pointer' }}
            className="m-auto"
          >
            <span className="material-icons ml-3 text-secondary" style={{ fontSize: '14px' }}>
              edit
            </span>
            <span className="font-weight-bold mx-1">Edit</span>
          </p> */}
        </div>
        <div>
          <span className="font-weight-bold mr-2">Status</span>
          <Switch checked={active} onChange={value => handleActiveChange(value)} />
        </div>
      </div>
    </>
  )
}

export default DetailHeader
