import React, { Fragment } from 'react'
import { withRouter, Redirect, matchPath } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import Loader from 'components/LayoutComponents/Loader'
import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'

const Layouts = {
  PUBLIC: PublicLayout,
  AUTH: LoginLayout,
  MAIN: MainLayout,
}

@withRouter
@connect(({ user }) => ({ user }))
class IndexLayout extends React.PureComponent {
  landingPath = ''

  previousPath = ''

  componentDidMount() {
    const {
      location: { pathname },
      dispatch
    } = this.props
    this.landingPath = pathname
    if (localStorage.getItem('povToken')) {
      dispatch({ type: 'user/SET_STATE', payload: { authorized: true, role: 'admin' } })
    }

  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { prevLocation } = prevProps
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user,
    } = this.props

    const isAuthPage = matchPath(pathname, {
      path: [/^\/user(?=\/|$)/i.test(pathname)],
    })

    // NProgress Management
    const currentPath = pathname + search
    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/') {
        return Layouts.PUBLIC
      }
      if (isAuthPage) {
        return Layouts.AUTH
      }
      return Layouts.MAIN
    }

    const Container = getLayout()
    const isUserAuthorized = user.authorized
    const isUserLoading = user.loading

    const BootstrappedLayout = () => {
      if (isUserLoading && !isUserAuthorized && !isAuthPage) {
        return <Loader />
      }
      if (!isAuthPage && !isUserAuthorized) {
        return <Redirect to="/user/login" />
      }
      if (isAuthPage && isUserAuthorized) {
        return <Redirect to={this.landingPath} />
      }

      return <Container>{children}</Container>
    }

    return (
      <Fragment>
        <Helmet titleTemplate="POV Admin" title="POV admin panel" />
        {BootstrappedLayout()}
      </Fragment>
    )
  }
}

export default IndexLayout
