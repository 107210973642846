// import {clearADSession} from 'redux/ads/action'
// import {clearSurveySession} from 'redux/survey/action'
// import {clearBusinessSession} from 'redux/business/action'
// import {clearFeedbackSession} from 'redux/feedback/action'
// import {clearCategorySession} from 'redux/category/action'

function addPageToUrl() {
  if (!document.location.search) {
    history.pushState(null, null, `${document.location.pathname}?page=1`)
  }
}

export default async function getLeftMenuData() {
  return [
    // {
    // 	title: 'Settings',
    // 	key: 'settings',
    // 	icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    // },
    // {
    // 	divider: true,
    // },
    {
      title: 'Home',
      key: 'home',
      url: '/dashboard',
      icon: 'dashboard',
      pro: false,
      clearSession: () => { }
    },
    // {
    // 	title : 'Setups',
    // 	key : 'setups',
    // 	url : '/setup',
    // 	// url : `/setup/${localStorage.getItem('business_id')}`,
    // 	icon : 'icmn icmn-equalizer2',
    // 	clearSession : () => {}
    // },
    {
      title: 'Feedbacks',
      key: 'feedback',
      url: '/feedback',
      icon: 'feedback',
      clearSession: (dispatch) => {
        // dispatch(clearFeedbackSession())
        // console.log(document.location)
        addPageToUrl()
      }
    },
    {
      title: 'Survey Management',
      key: 'survery_management',
      url: '/survey-management',
      icon: 'graphic_eq',
      clearSession: (dispatch) => {
        // dispatch(clearSurveySession())
        addPageToUrl()
      }
    },
    {
      title: 'Ad Management',
      key: 'ad_management',
      url: '/ad-management',
      icon: 'video_library',
      clearSession: (dispatch) => {
        // dispatch(clearADSession())
        addPageToUrl()
      }
    },
    // {
    // 	title : 'Hot Topics',
    // 	key : 'hot_topics',
    // 	url : '/hot-topics',
    // 	icon : 'icmn icmn-fire',
    // 	clearSession : (dispatch) => {
    // 		// console.log('')
    // 		addPageToUrl()
    // 	}
    // },
    {
      title: 'Activity',
      key: 'activity',
      url: '/activity',
      icon: 'view_compact',
      clearSession: (dispatch) => {
        // console.log('')
        // addPageToUrl()
      }
    },
    // {
    // 	title : 'Reports',
    // 	key : 'reports',
    // 	url : '/reports',
    // 	icon : 'icmn icmn-file-pdf',
    // 	clearSession : (dispatch) => console.log('')
    // },
    // {
    // 	title : 'Category',
    // 	key : 'category',
    // 	url : '/category',
    // 	icon : 'icmn icmn-folder',
    // 	clearSession : (dispatch) => {
    // 		// dispatch(clearCategorySession())
    // 		addPageToUrl()
    // 	}
    // },
    {
      title: 'Offers',
      key: 'offers',
      url: '/offers',
      icon: 'redeem',
      clearSession: dispatch => {
        // console.log(dispatch)
        addPageToUrl()
      }
    },
    {
      divider: true
    },
    // {
    // 	title : 'Business Setup',
    // 	key : 'business_setup',
    // 	url : '/business-setup',
    // 	icon : 'icmn icmn-user-plus',
    // 	clearSession : (dispatch) => {
    // 		// dispatch(clearBusinessSession())
    // 		addPageToUrl()
    // 	}
    // },
    // {
    // 	title : 'Profile',
    // 	key : 'profile',
    // 	url : '/profile',
    // 	icon : 'icmn icmn-user',
    // 	clearSession : dispatch => {
    // 		// console.log('')
    // 	}
    // }
    // {
    // 	title: 'Form Builder',
    // 	key: 'formBuilder',
    // 	url: '/form-builder',
    // 	icon: 'icmn icmn-hammer',
    // }
    // {
    // 	title : 'Discover',
    // 	key : 'discover',
    // 	url : '/discover',
    // 	icon : 'lightbulb_outline',
    // 	clearSession : (dispatch) => {}
    // },
    {
      title: 'Admin',
      key: 'admin',
      url: '/admin',
      icon: 'settings',
      clearSession: (dispatch) => { }
    }
  ]
}
