import Axios from 'axios'
import moment from 'moment';
import _ from 'lodash';

import { getApiCall, genericApiCall, postApiCall } from 'helpers/apiCall'
import { surveyResponseUrl, urlWithParams, baseUri } from '../helpers/urls'
import { generateFormData } from '../utils/generateFormData';

function getEncodedParams(body) {
  const params = new URLSearchParams()
  Object.keys(body).forEach(key => {
    if (body[key]) {
      params.append(key, body[key])
    }
  })
  return params
}

export const singleSurveyResponseApi = ({ object, successCallback, failureCallback, finalCallback, questions }) => {
  let formData = new FormData();
  formData.append('survey_id', object.survey_id);
  if (object.rating) {
    formData.append('rating', object?.rating);
  }
  // if (object.user_story) {
  //   formData.append('user_story', 'test story');
  // }
  // if (object.star_rating) {
  //   formData.append('star_rating', 5);
  // }
  object.responses.forEach((item, index) => {
    formData.append(`responses[${index}][question_id]`, item.question_id);
    if (typeof item.answer === 'object') {
      let havAddRemove = item.answer.add || item.answer.remove;
      if (moment.isMoment(item?.answer)) {
        let questionObj = _.find(questions, ['id', Number(item.question_id)]);
        if (questionObj?.config?.showTimeSelect === "true" && questionObj?.config?.showTimeSelectOnly === "true") {
          formData.append(`responses[${index}][answer]`, moment(item?.answer).format('HH:MM:SS'));
        }
        else if (questionObj?.config?.showTimeSelect === "true") {
          formData.append(`responses[${index}][answer]`, moment(item?.answer).format('YYYY-MM-DD HH:MM:SS'));
        }
        else {
          formData.append(`responses[${index}][answer]`, moment(item?.answer).format('YYYY-MM-DD'));
        }
        return
      }
      if (havAddRemove) {
        if (item.answer.add) {
          if (item.answer.add.length == 0) {
            if (item.type == "Image") {
              formData.append(
                `responses[${index}][answer][add][0]`,
                '',
              )
            }
          } else {
            item.answer.add.forEach((addImage, addInd) => {
              formData.append(
                `responses[${index}][answer][add][${addInd}]`,
                addImage,
              );
            });
          }
        }
        if (item.answer.remove) {
          item.answer.remove.forEach((removeImage, removeInd) => {
            formData.append(
              `responses[${index}][answer][remove][${removeInd}]`,
              removeImage,
            );
          });
        }
      }
      else {
        item.answer.forEach((ans, ind) => {
          formData.append(`responses[${index}][answer][${ind}]`, ans);
        });
      }
    } else {
      formData.append(`responses[${index}][answer]`, item.answer);
    }
  });
  // for (let [key, value] of formData) {
  //   console.log(`${key}: ${value}`)
  // }
  return (dispatch) => {
    postApiCall({ url: `${surveyResponseUrl}/bulk-create`, data: formData })
      .then((responseJson) => {
        // console.log('singleResponse response', responseJson);
        if (responseJson.success) {
          successCallback(responseJson)
        } else {
          failureCallback(responseJson)
        }
      })
      .catch((error) => console.log('error here', error))
      .finally(() => {
        finalCallback()
      });
  };
};


// const config = {
//   headers: {
//     'Content-Type': 'application/x-www-form-urlencoded',
//     Authorization: `Bearer ${localStorage.getItem('povToken')}`,
//   },
// }
// const params = getEncodedParams(object)
// // Axios.post(`${baseUri}/${surveyResponseUrl}`, formData, config)
