import { updateArr } from 'utils/helperFunction'
import * as actions from './action'

const initialStore = {
  data: [],
  branches: [],
  total: undefined,
  detail: [],
  currentPage: 1,
  searchParams: {},
  keyword: undefined,
  appliedBusinessData: {},
}

export default function BusinessReducer(state = initialStore, action) {
  switch (action.type) {
    case actions.SET_BUSINESS_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case actions.SET_BRANCHES_DATA:
      console.info(action.payload)
      return {
        ...state,
        branches: action.payload,
      }
    case actions.SET_BUSINESS_META:
      return {
        ...state,
        total: action.total || state.total,
        currentPage: action.currentPage,
        searchParams: action.searchParams,
      }
    case actions.SET_BRANCHES_META:
      return {
        ...state,
        // total: action?.total || state.total,
        // currentPage: action?.currentPage || 1,
        searchParams: action.searchParams,
      }
    case actions.DELETE_BUSINESS:
      const withoutDeleted = state.data.filter(business => business.id !== action.payload)
      return {
        ...state,
        data: withoutDeleted,
      }
    case actions.UPDATE_BUSINESS:
      const updated_business = updateArr(state.data, action.payload)
      return {
        ...state,
        data: updated_business,
      }
    case actions.UPDATE_BRANCH:
      const updated_branch = updateArr(state.data, action.payload)
      return {
        ...state,
        branches: updated_branch,
      }
    case actions.CLEAR_BUSINESS_SESSION:
      return {
        ...state,
        business: undefined,
        keyword: undefined,
        currentPage: 1,
      }
    case actions.SET_BUSINESS_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
      }
    case actions.SET_APPLIED_BUSINESS_DATA:
      return {
        ...state,
        appliedBusinessData: action.payload,
      }
    default:
      return state
  }
}
