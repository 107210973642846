export const SET_ACTIVITY_META = 'SET_ACTIVITY_META'
export const SET_ACTIVITY_DATA = 'SET_ACTIVITY_DATA'
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY'
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY'
export const CREATE_AN_ACTIVITY = 'CREATE_AN_ACTIVITY'

export function setActivityData(payload){
    return {
        type : SET_ACTIVITY_DATA,
        payload
    }
}

export function createAnActivity(payload){
    return {
        type : CREATE_AN_ACTIVITY,
        payload
    }
}

export function updateActivity(payload){
    return{
        type : UPDATE_ACTIVITY,
        payload
    }
}

export function setActivityMeta(meta){
    return {
        type : SET_ACTIVITY_META,
        total : meta.total,
        currentPage : meta.currentPage,
        searchParams : meta.searchParams
    }
}

export function deleteActivityAction(id){
    return{
        type : DELETE_ACTIVITY,
        payload : id
    }
}
