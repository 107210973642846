import React, { useState, useCallback, useEffect } from 'react'
import { Icon, Button, Input, Form, Tooltip } from 'antd'
import { withRouter } from 'react-router-dom'
import { InfoCircleTwoTone, InfoCircleOutlined } from '@ant-design/icons'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import { useDispatch, useSelector } from 'react-redux'
import CustomModal from 'components/CustomModal/CustomModal'
import ShowDescription from 'components/FormElements/ShowDescription'
import OfferComponent from 'components/FormElements/OfferComponent'
import CoinRewardComponent from 'components/FormElements/CoinRewardComponent'
import feedbackSvg from 'assets/feedback.svg'
import { shouldAddQuestion } from 'redux/ads/action'
import DetailHeader from 'components/FormElements/DetailHeader'
import BusinessDetailCard from 'components/BusinessComponents/BusinessDetailCard'
import InformationCard from 'components/FormElements/InformationCard'
import DetailTitle from 'components/FormElements/DetailTitle'
import { getAddressString } from 'utils/helperFunction'
import moment from 'moment'
// import Form from 'components/Profile/Form'

const FeedbackFields = ({ match, history, form, handleSubmit, loading, initialValues }) => {
  const [rewards, setRewards] = useState()
  const [action] = useState(match.params.action)
  const [isForDetail] = useState(
    history.location.pathname.split('/')[2] === 'detail' ? true : false,
  )
  const [offersData, setOffersData] = useState([])
  const [selectedOffer, setSelectedOffer] = useState()
  const [visibleSaveModal, setVisibleSaveModal] = useState(false)
  const [visibleOffersModal, setVisibleOffersModal] = useState(false)
  const [id] = useState(match.params.feedback_id)
  const [descriptions, setDescriptions] = useState()

  const errors = useSelector(state => state.errors.errors)

  // console.log(initialValues);

  const closeSaveModal = () => {
    setVisibleSaveModal(false)
  }
  const openSaveModal = e => {
    if (initialValues?.questions?.length > 3) {
      setVisibleSaveModal(true)
      return
    }
    e.preventDefault()
    dispatch(shouldAddQuestion(true))
    handleFormSubmit(e, 'draft')
  }
  const closeOffersModal = () => {
    setVisibleOffersModal(false)
  }
  const openOffersModal = () => {
    setVisibleOffersModal(true)
  }

  useEffect(() => {
    if (initialValues?.offer) {
      const selectedOffer = {
        key: initialValues.offer?.id,
        label: initialValues.offer?.name,
        description: initialValues.offer?.display_message,
      }
      setSelectedOffer(selectedOffer)
    }
  }, [initialValues])

  const dispatch = useDispatch()

  const handleRewardChange = useCallback(values => {
    // console.log(values)
    setRewards(values)
  }, [])

  const handleFormSubmit = (e, status) => {
    e.preventDefault()
    form.validateFieldsAndScroll((err, value) => {
      if (!err) {
        const allValues = {
          ...value,
          description: descriptions,
          budget_enabled: rewards?.checked ? '1' : '0',
          duration: rewards?.days ?? undefined,
          budget_ends_at: rewards.date
            ? moment(rewards.date).format('YYYY-MM-DD HH:mm:ss')
            : undefined,
          budget: rewards?.coin,
          reward_pov_coin: rewards?.perPerson ?? undefined,
          offer_id: selectedOffer?.key,
          status,
        }
        handleSubmit(allValues)
      }
    })
  }

  const saveAndAddQuestion = e => {
    e.preventDefault()
    dispatch(shouldAddQuestion(true))
    handleFormSubmit(e, 'draft')
  }

  const navigateToEdit = () => {
    history.push(`/feedback/edit/${id}`)
  }

  const requestForPublish = e => {
    e.preventDefault()
    dispatch(shouldAddQuestion(false))
    handleFormSubmit(e, 'pending_approval')
    setVisibleSaveModal(false)
  }

  const onOfferChange = useCallback(changedOffer => {
    setSelectedOffer(changedOffer)
  }, [])

  const { TextArea } = Input
  const { getFieldDecorator } = form
  const { Item } = Form

  return (
    <div
      className="d-flex flex-column justify-content-center"
      style={{ marginRight: '60px', marginLeft: '60px' }}
    >
      {isForDetail ? (
        <>
          {/* <div className="position-relative">
            <Button
              onClick={navigateToEdit}
              type="primary"
              style={{ top: -60, left: 840 }}
              icon="edit"
            >
              Edit
            </Button>
          </div> */}
          <DetailHeader
            business={initialValues?.business?.name}
            isActive={initialValues.active}
            type={initialValues?.campaign_type}
          />
          <div className="row mb-5">
            <div className="col-lg-5">
              <h5>Publicly Visible</h5>
              <BusinessDetailCard
                name={initialValues?.business?.name}
                duration={initialValues?.response_duration}
                image={initialValues?.business?.profile_pic}
                address={getAddressString(initialValues?.business?.address || {})}
              />
            </div>
            <div className="col-lg-7">
              <h5>Useful Information</h5>
              <InformationCard data={initialValues} />
            </div>
          </div>
        </>
      ) : null}
      <Form name="feedbackForm" layout="vertical" onSubmit={saveAndAddQuestion}>
        <div>
          {isForDetail ? (
            <DetailTitle />
          ) : (
            <h4 className="font-weight-bold">Feedback Point Details</h4>
          )}
          <Item
            className="my-0"
            validateStatus={errors?.title ? 'error' : undefined}
            help={errors?.title}
          >
            {getFieldDecorator('title', {
              initialValue: initialValues?.title,
              rules: [
                {
                  required: true,
                  message: 'Title is required',
                },
              ],
            })(
              <Input
                name="title"
                placeholder="Title For feedback point"
                size="large"
                disabled={isForDetail}
              />,
            )}
          </Item>
          <p className="text-small mt-0">
            Add a title, descriptive Feedback point title (Front Desk, Cash Counter, Showroom, After
            Sales)
          </p>
        </div>
        <div className="my-3">
          <Item
            className="my-0"
            validateStatus={errors?.description ? 'error' : undefined}
            help={errors?.description}
          >
            {getFieldDecorator('description', {
              initialValue: initialValues?.description,
            })(
              <CKEditor
                disabled={isForDetail}
                editor={ClassicEditor}
                onInit={editor => {
                  editor.editing.view.change(writer => {
                    writer.setStyle('height', '100px', editor.editing.view.document.getRoot())
                  })
                }}
                data={initialValues?.description ?? undefined}
                config={{
                  toolbar: ['heading', '|', 'bold', 'italic', '|', 'undo', 'redo', '|', 'link'],
                  link: {
                    addTargetToExternalLinks: true,
                  },
                  height: 620,
                }}
                onChange={(event, editor) => {
                  // errors.description = undefined
                  const data = editor.getData()
                  setDescriptions(data)
                }}
              />,
            )}
          </Item>
          <p className="text-small mt-0">Describe the reason behind creating this feedback point</p>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6">
            <CoinRewardComponent
              onChange={handleRewardChange}
              initialValues={initialValues}
              disabled={isForDetail}
              error={errors}
            />
          </div>
          <div className="col-lg-6 mt-5">
            <div>
              <ShowDescription
                title="POV Reward Coin"
                description="you can reward feedback giver by giving POV reward coins. Note this coin will be deducted from your wallet. If you are not clear about POV reward coins please contact POV CSR team."
              />
              <ShowDescription
                title="Recommended Range"
                description="we recommend spending within or above the range to get better results. The range is based on how similar feedback points was created."
                range="20,000 - 100,000 POV coins"
              />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="d-flex flex-column justify-content-center">
              <h4 className="text-center font-weight-bold">Add/Link Offers</h4>
              <p className="text-center font-weight-bold">
                Create attractive offers or discount for your customer. You can linked those offer
                on this feedback point
              </p>
              <div className="d-flex justify-content-center">
                {selectedOffer?.key ? (
                  <div className="d-flex justify-content-center">
                    <p
                      className="m-auto p-3 font-weight-bold bg-secondary text-light"
                      style={{ paddingTop: 'inherit' }}
                    >
                      {selectedOffer?.label}
                    </p>
                    <span className="mr-2 ml-3 align-self-center">
                      <Tooltip placement="top" title={selectedOffer?.description}>
                        <InfoCircleOutlined style={{ fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                    <Button
                      onClick={() => setSelectedOffer(undefined)}
                      className="mx-2 align-self-center"
                      disabled={isForDetail}
                    >
                      Clear
                    </Button>
                  </div>
                ) : (
                  <Button className="text-center" onClick={openOffersModal} disabled={isForDetail}>
                    <Icon type="plus" /> Add Offers
                  </Button>
                )}
                <CustomModal
                  isModalOpen={visibleOffersModal}
                  onOk={() => setVisibleOffersModal(false)}
                  onCancel={closeOffersModal}
                  // okText="Request For Publish"
                  // cancelText="Cancel for Now"
                  onModalCancel={closeOffersModal}
                  title="Select an Offer"
                  destroyOnClose
                >
                  <OfferComponent
                    onChange={onOfferChange}
                    initialValues={selectedOffer}
                    dispatch={dispatch}
                  />
                </CustomModal>
              </div>
            </div>
          </div>
        </div>
        {!isForDetail ? (
          <div className="card">
            <div className="card-body">
              {action === 'add' && (
                <p>
                  After you create feedback point, you must set question for this feedback. Once
                  your feedback is created, it will go for processing.
                </p>
              )}
              {action === 'edit' && (
                <p>
                  <span className="pr-2">
                    <InfoCircleTwoTone />
                  </span>
                  <span>You are currently editing an active feedback point.</span>
                </p>
              )}
              <div className="d-flex">
                <Button type="primary" className="mr-2" onClick={e => openSaveModal(e)}>
                  Save Now
                </Button>
                <CustomModal
                  isModalOpen={visibleSaveModal}
                  onOk={e => requestForPublish(e)}
                  onCancel={closeSaveModal}
                  okText="Request For Publish"
                  cancelText="Cancel for Now"
                  onModalCancel={closeSaveModal}
                  icon={feedbackSvg}
                  title="Reviewing your Request"
                  info={`we're reviewing your info we're reviewing your info to verify your Feedback Information and Confirming the information you entered matches public records for your business or organization. We'll send you a notification and email within a few hours`}
                />
                <Button type="ghost" className="mx-2" htmlType="submit" loading={loading}>
                  Save and Add question
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </Form>
    </div>
  )
}

const formWrapper = Form.create({ name: 'feedbackForm' })(FeedbackFields)

export default withRouter(formWrapper)
