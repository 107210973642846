import { updateArr } from 'utils/helperFunction'
import * as actions from './action'

const initalStore = {
  data: [],
  total: undefined,
  keyword: undefined,
  business: undefined,
  currentPage: 1,
  from_date: undefined,
  to_date: undefined,
  searchParams: {},
  shouldAddQuestionOnSubmit: true,
  allFeedbackCount: 0
}

export default function feebackReducer(state = initalStore, action) {
  switch (action.type) {
    case actions.SET_FEEDBACK_META:
      // console.log(action)
      return {
        ...state,
        total: action.total,
        currentPage: action.currentPage,
        searchParams: action.searchParams,
        allFeedbackCount: action?.isAllFeedback ? action?.total : state?.allFeedbackCount
      }
    case actions.SET_FEEDBACK_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case actions.DELETE_FEEDBACK:
      const withoutDeleted = state.data.filter(feeback => feeback.id !== action.payload)
      return {
        ...state,
        data: withoutDeleted,
      }
    case actions.CREATE_A_FEEDBACK:
      const newData = [action.payload, ...state.data]
      return {
        data: newData,
      }
    case actions.UPDATE_FEEDBACK:
      const updated_feeback = updateArr(state.data, action.payload)
      return {
        ...state,
        data: updated_feeback,
      }
    case actions.SET_FEEDBACK_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
        currentPage: 1,
      }
    case actions.SET_FEEDBACK_BUSINESS:
      return {
        ...state,
        business: action.payload,
      }
    case actions.SET_FEEDBACK_DATE:
      return {
        ...state,
        from_date: action.from_date,
        to_date: action.to_date,
      }
    case actions.CLEAR_FEEDBACK_SESSION:
      return {
        ...state,
        business: undefined,
        keyword: undefined,
        currentPage: 1,
      }
    case actions.UPDATE_FEEDBACK_STATUS:
      const newState = state.data.map(obj =>
        obj.id === action.payload.id
          ? { ...obj, active: action.payload.active, status: action.payload.status }
          : obj,
      )
      return {
        ...state,
        data: newState,
      }
    case actions.SHOULD_ADD_QUESTION:
      return {
        ...state,
        shouldAddQuestionOnSubmit: action.payload,
      }
    default:
      return state
  }
}
