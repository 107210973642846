export const baseUri = process.env.REACT_APP_BASE_URL

export const loginUrl = 'login'
export const registerUrl = 'register'
export const currentUserUrl = 'profile'
export const forgotPasswordUrl = 'password/reset-request'
export const resetPasswordUrl = 'password/reset'
export const socialLoginUrl = 'login/social'
export const surveyUrl = 'survey'
export const surveyResponseUrl = 'survey-response';
export const statusPatchUrl = 'change-status'
export const questionUrl = 'question'
export const bulkUpdateQuestionsUrl = 'bulk-update'
export const bulkCreateUrl = 'bulk-create'
export const adUrl = 'ad'
export const businessUrl = 'business'
export const branchesUrl = 'branches'
export const feedbackUrl = 'feedback'
export const categoryUrl = 'category'
export const responseUrl = 'survey-response'
export const defaultFeedbackUrl = 'default-feedback'
export const userUrl = 'user'
export const qrCodeUrl = 'qr-code'
export const offerUrl = 'offer'
export const activityUrl = 'activity'
export const invitationUrl = 'invitation'
export const roleUrl = 'role'
export const profileUrl = 'profile'
export const followupUrl = 'followup'
export const transactionUrl = 'transaction'
export const dashboardUrl = 'dashboard'
export const reportUrl = 'report'
export const changePasswordUrl = 'change-password'
export const basicDetailsUrl = 'profile/add-info';

export const urlWithParams = (url, params) => {
  let temp_url
  if (Object.keys(params).length) {
    temp_url = `${url}?`
  } else {
    temp_url = url
  }
  Object.keys(params).forEach((key, index) => {
    if (params[key]) {
      if (index !== 0) {
        temp_url += `&${key}=${params[key]}`
      } else {
        temp_url += `${key}=${params[key]}`
      }
    }
  })
  return temp_url
}
