export function updateArr(arr, obj) {
  let new_data
  arr.forEach((a, index) => {
    if (a.id === obj.id) {
      new_data = [...arr]
      new_data[index] = { ...obj }
    }
  })
  return new_data
}

const rightElementType = [
  'Dropdown',
  'TextInput',
  'Tags',
  'Checkboxes',
  'RadioButtons',
  'NumberInput',
  'TextArea',
  'Image',
  'Rating',
  'DatePicker',
  'Range',
]

export function createFormFromApi(arr, readOnly) {
  const temp_arr = []
  if (arr.length) {
    arr.forEach((item, index) => {
      let config
      let valid_config_for_element = {}
      const allConfig = {
        src: item.config ? item.config.src : '',
        max_value: item.config ? parseInt(item.config.max_value, 10) : undefined,
        min_value: item.config ? parseInt(item.config.min_value, 10) : undefined,
        max_label: item.config ? item.config.max_label : undefined,
        min_label: item.config ? item.config.min_label : undefined,
        step: item.config ? parseInt(item.config.step, 10) : undefined,
        default_value: item.config ? parseInt(item.config.default_value, 10) : undefined,
        dateFormat: item.config ? item.config.dateFormat : undefined,
        defaultToday: item.config && item.config.defaultToday === 'true' ? true : false,
        showTimeSelect: item.config && item.config.showTimeSelect === 'true' ? true : false,
        showTimeSelectOnly: item.config && item.config.showTimeSelectOnly === 'true' ? true : false,
        timeFormat: item.config ? item.config.timeFormat : undefined,
      }
      if (item.type === 'DatePicker') {
        valid_config_for_element = {
          default_value: allConfig.default_value,
          dateFormat: allConfig.dateFormat,
          defaultToday: allConfig.defaultToday,
          showTimeSelect: allConfig.showTimeSelect,
          showTimeSelectOnly: allConfig.showTimeSelectOnly,
          timeFormat: allConfig.timeFormat,
        }
      } else if (item.type === 'Range') {
        valid_config_for_element = {
          max_label: allConfig.max_label,
          min_label: allConfig.min_label,
          max_value: allConfig.max_value,
          min_value: allConfig.min_value,
          step: allConfig.step,
          default_value: allConfig.default_value,
        }
      } else if (item.type === 'Image') {
        valid_config_for_element = {
          src: allConfig.src,
        }
      }
      Object.keys(valid_config_for_element).forEach(key => {
        if (typeof valid_config_for_element[key] !== 'undefined') {
          config = {
            ...config,
            [key]: valid_config_for_element[key],
          }
        }
      })
      // console.log(config);
      if (
        item.type === 'Dropdown' ||
        item.type === 'TextInput' ||
        item.type === 'Tags' ||
        item.type === 'Checkboxes' ||
        item.type === 'RadioButtons' ||
        item.type === 'NumberInput' ||
        item.type === 'TextArea' ||
        item.type === 'Image' ||
        item.type === 'Rating' ||
        item.type === 'DatePicker' ||
        item.type === 'Range'
      ) {
        const opt_arr = []
        const obj = {
          canHaveAlternateForm: false,
          canHaveAnswer: false,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrent: true,
          canHaveOptionValue: true,
          canHavePageBreakBefore: false,
          canPopulateFromApi: false,
          static: undefined,
          // readOnly : readOnly ? true : false,
          required: item.is_required ? true : false,
          text: item.type,
          element: item.type,
          label: item.question,
          showDescription: true,
          description:
            !item.description || item.description === 'undefined' ? undefined : item.description,
          field_name: `${item.type}_${Math.random()}`,
          id: `${item.id}`,
          ...config,
        }
        if (item.options) {
          Object.keys(item.options).forEach(key => {
            const option_obj = {
              label: item.options ? item.options[key].text : undefined,
              text: item.options ? item.options[key].text : undefined,
              value: item.options ? item.options[key].value : undefined,
              key: Math.random(),
            }
            opt_arr.push(option_obj)
          })
        }
        let final_obj = {
          ...obj,
        }
        if (opt_arr.length) {
          final_obj = {
            ...obj,
            options: opt_arr,
          }
        }
        temp_arr.push(final_obj)
      }
    })
  }
  return temp_arr
}

export function getAddressString(address) {
  let addy = ''
  Object.keys(address).forEach((key, index) => {
    const last = Object.keys(address).length - 1
    if (address[key] && address[key] !== 'undefined') {
      addy += `${address[key]}`
      if (index !== last) {
        addy += ', '
      }
    }
  })
  if (!addy) {
    addy = 'Address Not Available'
  }
  return addy
}

export function getSearchParamsFromUrl(url) {
  let params = {}
  if (url) {
    const splited = url.split('?')[1].split('&')
    splited.forEach(split => {
      const splitAgain = split.split('=')
      let param = {
        [splitAgain[0]]: splitAgain[1],
      }
      params = {
        ...params,
        ...param,
      }
    })
  }
  return params
}

export function blobToImage(blob) {
  if (blob) {
    const objectUrl = URL.createObjectURL(blob)
    return objectUrl
  }
}

export function getsearchParamsFromRequestBody({ page, params }) {
  let searchParams = {
    page,
  }
  if (params.from_date) {
    searchParams = {
      ...searchParams,
      date_from: params.from_date,
    }
  }

  if (params.to_date) {
    searchParams = {
      ...searchParams,
      date_to: params.to_date,
    }
  }

  if (params.key) {
    searchParams = {
      ...searchParams,
      keyword: params.key,
    }
  }

  if (params.category) {
    searchParams = {
      ...searchParams,
      category: params.category,
    }
  }

  return searchParams
}
