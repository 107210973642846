import { updateArr } from 'utils/helperFunction'
import * as actions from './action'

const initialStore = {
  data: [],
  currentPage: 1,
  total: undefined,
  searchParams: {},
}

export default function roleReducer(state = initialStore, action) {
  switch (action.type) {
    case actions.SET_ROLE_META:
      return {
        ...state,
        total: action.total || state.data?.length,
        currentPage: action.currentPage,
        searchParams: action.searchParams,
      }
    case actions.SET_ROLE_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case actions.CREATE_ROLE:
      const newData = [action.payload, ...state.data]
      return {
        ...state,
        data: newData,
      }
    case actions.UPDATE_ROLE:
      const newState = state.data.map(obj =>
        obj.email === action.payload.email ? { ...obj, role: action.payload.role } : obj,
      )
      return {
        ...state,
        data: newState,
      }
    case actions.DELETE_ROLE:
      const withoutDeleted = state.data.filter(role => role.email !== action.payload.email)

      return {
        ...state,
        data: withoutDeleted,
      }
    default:
      return state
  }
}
